<template>
    <div class="old-list">
        <div class="w" v-loading="$store.state.showLoding">
           
            <div class="screen-list" v-if="!showScreen&&pitchScreen.length>0" >
                <div class="screen-item" v-for="(scr,index) in pitchScreen" :key="index">{{scr}}</div>
                <i class="el-icon-close" @click="clearActiveFilter('pitchScreen')"></i>
            </div>
            
            <div class="condition-tabel" v-show="showCond" >
                
                <div class="coudition-group" v-if="$store.getters.notLimitBrands">
                    <div class="label">配件品牌</div>
                    <div class="coudition">
                        <div class="coudition-left pabo10" v-if="screenList && screenList.oenaturebrand" :style="{'height':couditionH}">
                            <template v-for="(item,index) of screenList.oenaturebrand" >
                                <div class="coudition-daxiao" :key='"oenaturebrand"+index' v-if="item.oenaturebrand">
                                    <span  class="item" :class="{'active':searchData.oenaturebrand.includes(item.oenaturebrand)}" @click="activeFilter('oenaturebrand',item.oenaturebrand)">
                                        {{item.oenaturebrand}}
                                    </span>
                                </div>
                            </template>
                            <div class="toggle">
                                <i v-if="couditionH=='auto'" class="el-icon-arrow-up"  @click="toggleCH"></i>
                                <i v-else class="el-icon-arrow-down" @click="toggleCH"></i>
                            </div>
                        </div>
                        <div class="coudition-right">
                            <span @click="clearActiveFilter('oenaturebrand')">
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>

                <!-- 配件分类 -->
                <div class="coudition-group">
                    <div class="label">配件分类</div>
                    <div class="coudition">
                        <div class="coudition-left" v-if="screenList && screenList.oesort">
                            <template v-for="(item,index) of screenList.oesort" >
                                <div  class="coudition-daxiao" :key='"oesort"+index' v-if="item.oesort">
                                    <span  class="item" :class="{'active':searchData.oesort.includes(item.oesort)}" @click="activeFilter('oesort',item.oesort)">
                                        {{item.oesort}}
                                    </span>
                                </div>
                            </template>
                        </div>
                        <div class="coudition-right">
                            <span @click="clearActiveFilter('oesort')">
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>
                <!-- 供应商 -->
                <div class="coudition-group">
                    <div class="label">供应商</div>
                    <div class="coudition">
                        <div class="coudition-left"  v-if="screenList && screenList.store">
                            <template v-for="(item,index) of screenList.store">
                                <div  class="coudition-daxiao"  :key='index' v-if="item.store_id">
                                    <span class="item" :class="{'active':searchData.store_name.includes(item.store_name)}" @click="activeFilter('store_name',item.store_name,item.store_id)">
                                        {{item.store_name}}
                                    </span> 
                                </div>
                            </template>
                        </div>
                        <div class="coudition-right">
                            <span @click="clearActiveFilter('store')">
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <!-- 分组按钮 -->
            <div class="top-handle">
                <div class="group-list">
                    <div class="group-item" @click="cutGroup('')" :class="{'active':group_type==''}">
                        综合排序
                    </div>
                  
                    <div class="group-item" style="opacity: 0;">
                        按商家分组
                    </div>
                    <div class="group-item noin-item" @click="cutGroup('no')" :class="{'active':group_type=='no'}" >
                      <template v-if="noInquireList.length>0">
                        <span>
                          没有查到({{noInquireList.length}})
                        </span>
                        <el-button class="ex-btn" size="mini" text round type="danger" @click="exNoInquire('')">导出未查到</el-button>
                      </template>
                      <el-button  v-if="$store.state.batchs.length>0" class="ex-btn ex-btn1" size="mini" text round type="primary" @click="exNoInquire('all')">导出采购列表</el-button>
                    </div>
                </div>
                
                <div class="short-pag" v-if="storeGoodsList.length>0">
                    <el-checkbox class="filterZero" v-model="sort_self" @change="query(true)" v-if="$store.getters.notLimitBrands" >自有品牌优先</el-checkbox>
                    <el-checkbox class="filterZero"  v-model="showZero" @change="query(true)">过滤0库存</el-checkbox>
                    <div class="all">
                        {{storeGoodsList[0].total}}条
                    </div>
                    
                    <div class="up-down" >
                       <div class="el-icon-caret-top" @click="shortCurpageChange(1)" :class="{'notCli':storeGoodsList[0].curpage==1}">
                       </div>
                       <div class="el-icon-caret-bottom" @click="shortCurpageChange(2)" :class="{'notCli':storeGoodsList[0].curpage==Math.ceil(storeGoodsList[0].total/storeGoodsList[0].page)}">

                       </div>
                    </div>
                   
                </div>
                <div class="sort-right" @click="toggleScreen">
                    {{message}}
                    <i class="el-icon-arrow-up" v-if="showScreen"></i>
                    <i class="el-icon-arrow-down" v-else></i>
                </div>
            </div>
            
            <!-- 商品目录 -->
            <div class="product" v-if="group_type!='no'">
                <ul class="teble-head">
                    <li v-for="(item,index) of listAttr" :key="index" :class="item.key">
                        {{item.label}}
                        
                        <div class="ico">
                           
                            <img src="../../assets/commom/up.png" v-if="sortActive==item.key+1" alt="" @click="sortChange(item.key,2,index)" >

                            <img src="../../assets/commom/down.png" v-else alt="" @click="sortChange(item.key,1,index)" >
                        </div>
                    </li> 
                    <li class="specification">
                        规格
                    </li>
                    <li class="numAd">
                        订货数量
                    </li>
                    <!-- <li>区域</li> -->
                    <li class="handle-box">
                        操作
                    </li>  
                </ul>
            </div>

            <!-- <div class="history" v-if="!$store.state.searchText">
                <span>历史搜索记录</span>
            </div> -->
            <!-- 商品详情 -->
            <div class="available" v-if="group_type=='no'">
                <div v-for="(item,index) in noInquireList" :key="index">
                    没查到 <span class="red">{{item.code}}</span> 相关配件，请试试 <span class="blue" @click="postDemand">发布需求</span> 。
                </div>
            </div>
            <div v-else-if="group_type=='parts'" >
              
                <div v-for="(item,index) of shopGoodsList" :key='"list"+index'>
                    <div class="part-storeinfo" v-if="item.goods_list.length>0">{{item.store_info}}</div>
                    <div class="cargo" v-if="item.goods_list.length>0"> 
                            <ul >
                                <li :class="{'case-list':true,'isAll':item.isShowAll}" v-for="(goods,childIndex) of item.goods_list"
                                        :key="'child'+childIndex" @click.stop="showGoodsInfo(goods)">
                                    <div class="details">
                                       
                                        <div class="isOe">
                                            <div class="isOe-left">
                                                <div class="isOe-top">
                                                    
                                                        <div class="name">{{goods.oenaturebrand}}</div>
                                                    
                                                    <div class="number"  >
                                                        {{goods.oecode}}
                                                    </div>
                                                  
                                                    <div class="tag-icon" v-if="goods.isExchange">
                                                        互
                                                    </div>
                                                </div>

                                                <div class="vehicleModel"  :title="goods.vehicletype">车型:{{goods.vehicletype}}</div>
                                            </div>
                                            <div class="isOe-right">
                                                <img v-if="goods.store_id=='2197'" src="../../assets/find/jiangsu.png" alt="">
                                                <!-- <img v-else src="../../assets/find/shanghai.png" alt=""> -->
                                            </div>
                                        </div>

                                        <div class="productName" :title="goods.goods_name">{{goods.goods_name}}</div>
                                        
                                        <div class="encoding" :title="goods.sccode">{{goods.sccode}}</div>
                                        <div class="brand" :title="goods.oenaturebrand" :class="{'selfBrand':goods.hnw_self}">
                                            {{goods.oenaturebrand}}
                                            <span class="hnw-self-brand" v-if="goods.hnw_self">
                                                自主品牌
                                            </span>
                                        </div>
                                        
                                        <div class="inventory" :class="{'disabled-color':goods.goods_storage==0}">
                                            
                                            <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                            <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                            <!-- Cang -->
                                            <div style="font-size: 12px;color: red;" v-if="$store.state.member_id.indexOf('1780-')!=-1"> 
                                              <div style="margin: 5px 0;">上海:
                                                <span v-if="$store.state.look_inv==1">{{goods.sh_storage}}</span>
                                                <span v-else>{{goods.sh_storage>0?"有货":"无货"}}</span> 
                                              </div>
                                              <div>丹阳:
                                                <span v-if="$store.state.look_inv==1">{{goods.dy_storage}}</span>
                                                <span v-else>{{goods.dy_storage>0?"有货":"无货"}}</span> 
                                              </div>
                                              <div @click="openAll(goods)" style="cursor: pointer;margin: 10px 0;"><span style="padding: 2px;color: #59a8f8;border: 1px solid #59a8f8;">货位详情</span></div>
                                            </div>
                                            <div v-else style="color:red;font-size:12px;margin-top:5px;font-weight:bold;">{{goods.cangTip}}</div>
                                        </div>
                                        
                                        <div class="TheOrderPrice" :title="goods.goods_price"> ¥ {{goods.goods_price}}
                                          <span v-if="goods.brand_ratio_price">({{goods.brand_ratio_price}})</span>
                                        </div>
                                        <div class="specification">  {{goods.spec}}</div>
                                        
                                        <div class="numAd" @click.stop>
                                            
                                            <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
                                            <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
                                            <span class="goods-Fcl-tit" v-if="goods.brand_ratio_price">
                                                整箱更优惠
                                            </span>
                                            <span class="goods-Fcl-tit red" v-if="goods.batch_pur_num_bey">
                                                库存不足{{goods.batch_pur_num}}
                                            </span>
                                        </div>
                                        <!-- <div class="area">{{goods.house||'总部仓'}}</div> -->
                                        <div class="handle-box" @click.stop>
                                        
                                            <el-button class="add-btn" :class="{'ycg-btn':goods.goods_storage==0}" @click="joinCart(goods,index,childIndex,item.store_info)">
                                                {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                                               
                                            </el-button> 
                                            <div class="addition">
                                                <span v-if="goods.cartNum">
                                                    已添加 {{goods.cartNum}} 件
                                                </span>
                                            </div>
                                            <!-- v-if="goods.exchange.length>0" -->
                                            <!-- @click="showChangeParts(goods)" -->
                                            <el-popover
                                              placement="left"
                                              width="260"
                                              popper-class="pop-replace-list"
                                              v-model="goods.replacePopVis"
                                              trigger="click">
                                              <div class="pop-close"  @click.stop="goods.replacePopVis=false">
                                                <i class="el-icon-close"></i>
                                              </div>
                                              <el-table  :border="false" class="replace-table"  :data="buyStoreList" height="300">
                                                <el-table-column width="60" type="index" label="序号"></el-table-column>
                                                <el-table-column width="120" property="oecode" label="互换件号">
                                                  <template slot-scope="scope">
                                                      <span >{{scope.row.oecode}}</span>
                                                      <HnwCopy :data="scope.row.oecode" ></HnwCopy>
                                                  </template>
                                                </el-table-column>
                                                <el-table-column width="80" property="address" label="操作">
                                                  <template slot-scope="scope">
                                                    <span class="show-btn" v-if="scope.row.goods_id" @click="toSearch(scope.row)">
                                                      在售
                                                    </span>
                                                  
                                                  </template>
                                                </el-table-column>
                                              </el-table>
                                              
                                              <div class="replace-btn" @click="showChangeParts(goods)" type="text" slot="reference" text v-if="goods.exchange.length>0&&$store.getters.notLimitBrands">查看互换件</div>
                                            </el-popover>
                                        </div>
                                            
                                        <div class="stock" v-if="goods.self_storage">自有库存{{goods.self_storage || 0}}</div>
                                        <!-- <div class="addition"  v-if="goods.cartNum">已添加 {{goods.cartNum}} 件</div> -->
                                    </div>
                                    
                                    <!--
                                      <span>
                                          {{goods.orderInfo.member_name}}
                                      </span> 
                                    -->
                                    <div class="goods-ex-msg" v-if="goods.orderInfo">
                                      <div class="buyHis"   @click.stop="readHisList(goods.goods_id)" >
                                        您最近一次购买：
                                        <span>
                                            {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                                        </span>
                                        <span>
                                            {{goods.orderInfo.goods_num}} 件
                                        </span>
                                        <span class="readMore" >
                                          更多
                                        </span>
                                      </div>
                                    </div>
                                        <!-- <span>
                                            {{$hnwTools.getOrderStateText(goods.orderInfo.order_state)}}
                                        </span> -->
                                      <!-- 测试预购单顺便 -->
                                      <!-- <div class="store-name" v-if="goods.store_name">
                                          {{goods.store_name}}
                                      </div> -->
                                </li> 
                                    <!-- <div class="pagings" v-if="group_type==''&& $store.state.batchs.length==0" > 
                                        <el-pagination
                                            background
                                            @size-change="query(true)"
                                            @current-change="query(true)"
                                            :current-page.sync="currentPage"
                                            :page-sizes="[ 15,20, 30,40]"
                                            :page-size.sync="pageSize"
                                            layout="total, sizes, prev, pager, next, jumper"
                                            :total="Math.ceil(item.total)">
                                        </el-pagination>
                                    </div>
                                    <div class="shoppingAll" v-else>
                                        <div @click="joinCartAll">
                                            全部加入购物车
                                        </div>
                                        
                                    </div> -->
                            </ul>
                            
                    </div> 
                </div>
                <div class="shoppingAll">
                    <div @click="joinCartAll">
                        全部加入购物车
                    </div>
                </div>
            </div>
            

            <div class="cargo" v-else v-for="(item,index) of storeGoodsList" :key='"list"+index'>
                    <ul >
                        <li :class="{'case-list':true,'isAll':item.isShowAll}" v-for="(goods,childIndex) of item.goods_list"
                                :key="'child'+childIndex" @click.stop="showGoodsInfo(goods)">
                            <div class="details">
                                <!-- <div class="id-bs">
                                  {{ $store.state.member_id }}
                                  <div class="bordiv"></div>
                                  <div class="bordiv1"></div>
                                </div> -->
                                <div class="isOe">
                                    <div class="isOe-left">
                                        <div class="isOe-top">
                                           
                                            <HnwCopy :data="goods.oecode" ></HnwCopy>
                                            <div class="name">{{goods.oenaturebrand}}</div>
                                           
                                            <div class="number"  @click.stop="showGoodsInfo(goods)">
                                                {{goods.oecode}}
                                            </div>
                                            <!-- <div class="tag-icon" v-if="goods.exchange.length>0">
                                                互
                                            </div> -->
                                            <div class="tag-icon" v-if="goods.isExchange">
                                                互
                                            </div>
                                        </div>

                                        <div class="vehicleModel" @click.stop="showGoodsInfo(goods)" :title="goods.vehicletype">车型:{{goods.vehicletype}}</div>
                                    </div>
                                    <div class="isOe-right">
                                        <img v-if="goods.store_id=='2197'" src="../../assets/find/jiangsu.png" alt="">
                                        <!-- <img v-else src="../../assets/find/shanghai.png" alt=""> -->
                                    </div>
                                </div>

                                <div class="productName" :title="goods.goods_name">{{goods.goods_name}}</div>
                                
                                <div class="encoding" :title="goods.sccode">{{goods.sccode}}
                                  <HnwCopy :data="goods.sccode" ></HnwCopy>
                                </div>
                                <div class="brand" :title="goods.oenaturebrand" :class="{'selfBrand':goods.hnw_self}">
                                    {{goods.oenaturebrand}}
                                    <span class="hnw-self-brand" v-if="goods.hnw_self">
                                        自主品牌
                                    </span>
                                </div>
                                
                                <div v-if="!$store.getters.isVisitor" class="inventory" :class="{'disabled-color':goods.goods_storage==0}">
                                    <template v-if="!$store.state.member_id.includes('1780-')">
                                        <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                        <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                    </template>
                                    <!-- Cang -->
                                    <div style="font-size: 12px;color: red;" v-if="$store.state.member_id.includes('1780-')"> 
                                      <div style="margin: 5px 0;">上海:
                                        <span v-if="$store.state.look_inv==1">{{goods.sh_storage}}</span>
                                        <span v-else>{{goods.sh_storage>0?"有货":"无货"}}</span> 
                                      </div>
                                      <div>丹阳:
                                        <span v-if="$store.state.look_inv==1">{{goods.dy_storage}}</span>
                                        <span v-else>{{goods.dy_storage>0?"有货":"无货"}}</span> 
                                      </div>
                                      <div @click="openAll(goods)" style="cursor: pointer;margin: 10px 0;"><span style="padding: 2px;color: #59a8f8;border: 1px solid #59a8f8;">货位详情</span></div>
                                    </div>
                                    <div v-else style="color:red;font-size:12px;margin-top:5px;font-weight:bold;">{{goods.cangTip}}</div>
                                </div>
                                <div class="inventory" v-else>
                                  会员可见
                                </div>
                                <div class="TheOrderPrice"> ¥ {{goods.goods_price}}
                                    <span v-if="goods.brand_ratio_price">({{goods.brand_ratio_price}})</span>
                                </div>
                                <div class="specification">  {{goods.spec}}</div>
                                
                                <div class="numAd" @click.stop>
                                     
                                    <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
                                    <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
                                    <span class="goods-Fcl-tit" v-if="goods.brand_ratio_price">
                                        整箱更优惠
                                    </span>
                                    <span class="goods-Fcl-tit red" v-if="goods.batch_pur_num_bey">
                                        库存不足{{goods.batch_pur_num}}
                                    </span>
                                </div>
                                <!-- <div class="area">{{goods.house||'总部仓'}}</div> -->
                                <div class="handle-box" @click.stop v-if="!$store.getters.isVisitor">
                                 
                                    <el-button class="add-btn" :class="{'ycg-btn':goods.goods_storage==0}" @click="joinCart(goods,index,childIndex,item.store_info)">
                                        {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                                        <!-- 加入购物车 -->
                                    </el-button> 
                                    <div class="addition">
                                        <span v-if="goods.cartNum">
                                            已添加 {{goods.cartNum}} 件
                                        </span>
                                    </div>
                                    <!-- v-if="goods.exchange.length>0" -->
                                    <el-popover
                                      placement="left"
                                      width="260"
                                      popper-class="pop-replace-list"
                                      trigger="click"
                                      v-model="goods.replacePopVis"
                                    >
                                      <div class="pop-close"  @click.stop="goods.replacePopVis=false">
                                        <i class="el-icon-close"></i>
                                      </div>
                                      <el-table :border="false" class="replace-table"  height="300" :data="buyStoreList">
                                        <el-table-column width="60" type="index" label="序号"></el-table-column>
                                        <el-table-column width="120" property="oecode" label="互换件号">
                                          <template slot-scope="scope">
                                              <span >{{scope.row.oecode}}</span>
                                              <HnwCopy :data="scope.row.oecode" ></HnwCopy>
                                          </template> 
                                        </el-table-column>
                                        <el-table-column width="80" property="address" label="操作">
                                          <template slot-scope="scope">
                                            <span class="show-btn"  v-if="scope.row.goods_id" @click="toSearch(scope.row)">
                                              在售
                                            </span>
                                          
                                          </template>
                                        </el-table-column>
                                      </el-table>
                                      <div class="replace-btn"  @click="showChangeParts(goods)" type="text" slot="reference" text v-if="goods.exchange.length>0&&$store.getters.notLimitBrands">查看互换件</div>
                                      
                                    </el-popover>
                                    <!-- <div class="replace-btn" @click="showChangeParts(goods)" v-if="goods.exchange.length>0&&$store.getters.notLimitBrands">查看互换件</div>  -->
                                </div>
                                <div class="handle-box" v-else>

                                </div>
                                <div class="stock" v-if="goods.self_storage">自有库存{{goods.self_storage || 0}}</div>
                                <!-- <div class="addition"  v-if="goods.cartNum">已添加 {{goods.cartNum}} 件</div> -->
                            </div>
                            
                            <div class="goods-ex-msg" v-if="goods.orderInfo">
                                <div class="buyHis"   @click.stop="readHisList(goods.goods_id)" >
                                    您最近一次购买：
                                    <span>
                                        {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                                    </span>
                                    <!-- 
                                    <span>
                                        {{goods.orderInfo.member_name}}
                                    </span> -->
                                    <span>
                                        {{goods.orderInfo.goods_num}} 件
                                    </span>
                                    <!-- <span>
                                        {{$hnwTools.getOrderStateText(goods.orderInfo.order_state)}}
                                    </span> -->
                                    <span class="readMore" >
                                        更多
                                    </span>
                                </div>
                                <!-- 测试预购单顺便 -->
                                <!-- <div class="store-name" v-if="goods.store_name">
                                    {{goods.store_name}}
                                </div> -->
                            </div>
                        </li>
                            <!-- <div class="pagings" v-if="group_type==''&& $store.state.batchs.length==0" > -->
                            <div class="pagings" v-if="group_type==''&& $store.state.batchs.length==0" >
                              
                                <el-pagination
                                    background
                                    @size-change="query(true)"
                                    @current-change="query(true)"
                                    :current-page.sync="currentPage"
                                    :page-sizes="[ 15,20, 30,40]"
                                    :page-size.sync="pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="Math.ceil(item.total)">
                                </el-pagination>
                            </div>
                            <div class="shoppingAll" v-else>
                                <div @click="joinCartAll">
                                     全部加入购物车
                                </div>
                                
                            </div>
                    </ul>
                    
            </div>
                <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
                    <div class="his-list">
                        <div class="list-head">
                            <div>购买时间</div>
                            <div>发货时间</div>
                            <div>购买人</div>
                            <div class="col-100">价格</div>
                            <div class="col-100">购买个数</div>
                            <div class="col-100">订单状态</div>
                        </div>
                        <div class="list-body">
                            <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                                <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                                <div>{{$hnwTools.formatDateTime(his.delay_time,'ymd')||"无"}}</div>
                                <div>{{his.member_name}}</div>
                                <div  class="col-100">{{his.goods_price}}</div>
                                <div  class="col-100">{{his.goods_num}}</div>
                                <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                            </div>
                        </div>
                    </div>
                </el-dialog>
                <div class="store-vis">
                    <el-dialog
                        :visible.sync="storeVis"
                        width="400px"
                        :show-close="false"
                    >
                    
                        <div class="tit">
                            互换件列表
                            <i class="el-icon-close" @click="storeVis=false"></i>
                        </div>
                        <div class="list-body">
                            <div class="list-head">
                               
                                <div class="hhj-index">序号</div>
                                <div class="oe">互换件号</div>
                              
                                <div class="handle">操作</div>
                            </div>
                            <div class="list-row" v-for="(goods,index) in buyStoreList" :key="index">
                                <!-- <div>{{goods.store_name}}</div> -->
                               
                                <div class="hhj-index">
                                    {{index+1}}
                                </div>
                                <div class="oe">
                                    {{goods.oecode}}
                                </div>
                               
                                <template v-if="goods.goods_id">
                              
                                  <div class="handle">
  
                                      <span class="btn" @click="toSearch(goods.oecode)">
                                          在售列表
                                         
                                      </span>
                                  </div>
                                </template>
                                <template v-else>
                                 
                                  <div class="handle">
  
                                      <span class="btn" >
                                         
                                          <span>--</span>
                                      </span>
                                  </div>
                                </template>
                            </div>
                        </div>
                    </el-dialog>
                </div>

            <!-- 弹出框 -->
                <div class="hezi" >
                    <el-dialog
                    :visible.sync="dialogVisible"
                    width="760px"
                    top="8vh"
                    >
                        <DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
                    </el-dialog>
                </div>
            <!-- 展开箭头 -->
            <!-- <div class="pull" @click="goPull">
                <i class="el-icon-arrow-down jiantou" v-if="!showAll"  ></i>
            <i class="el-icon-arrow-up  jiantou" v-if="showAll" ></i>
            </div> -->
            <!-- 货位详情 -->
            <el-dialog
            title="货位详情"
            :visible.sync="openAllotion"
            width="50%"
            :before-close="handleClose">
            
              <div class="alltion">
                <div>OE号</div>
                <div>仓库</div>
                <div>货位</div>
                <div>库存</div>
              </div>

              <template v-if="goodsAllotion.dy.list.length>0">
                <div class="alltion">
                  <div style="width: 100%;">丹阳</div>
                </div>
                <div class="alltion" v-for="(item) in goodsAllotion.dy.list" :key="item.shelfId">
                  <div class="item">{{item.code}}</div>
                  <div class="item">{{item.warehouseName}}</div>
                  <div class="item">{{item.shelfName}}</div>
                  <div class="item">{{item.shelfStockNum}}</div>
                </div>
              </template>
              <template v-if="goodsAllotion.sh.list.length>0">
                <div class="alltion">
                  <div style="width: 100%;">上海</div>
                </div>
                <div class="alltion" v-for="(item) in goodsAllotion.sh.list" :key="item.shelfId">
                  <div class="item">{{item.code}}</div>
                  <div class="item">{{item.warehouseName}}</div>
                  <div class="item">{{item.shelfName}}</div>
                  <div class="item">{{item.shelfStockNum}}</div>
                </div>
              </template>
              <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="openAllotion = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="openAllotion = false">确 定</el-button>
              </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { export_json_to_excel } from "../../common/utils/Export2Excel"
export default {
  name: "findIndex",
  data() {
    return {
      gooodsHisList: [],
      hisVis: false,
      storeGoodsList: [], //搜索框数据
      // showPull:false,         //弹框-隐藏套内商品
      dialogVisible: false, //显示隐藏弹出框
      message: "展开筛选",
      showCond: false, //筛选显示
      checked: false, //多选框
      showSearch: false, //数据展示
      // showAll:false,          //弹出框数据展示
      showbelow: false, //筛选展示
      showScreen: false,
      showZero: false,
      num: 1,
      sort_type: "goods_storage",
      sort_way: 1,
      sort_self: true,
      showList: [],
      start_list: [],
      sortActive: "",
      group_type: "",
      openAllotion:false,//货位详情
      goodsAllotion:{
        dy:{
            title:"丹阳",
            list:[]
        },
        sh:{
            title:"上海",
            list:[]
        }
      },
      listAttr: [
        { key: "oecode", label: "OE号", ckbox: false },
        { key: "goods_name", label: "商品名称", ckbox: false },
        { key: "sccode", label: "生产编码", ckbox: false },
        { key: "oenaturebrand", label: "配件品牌", ckbox: false },
        { key: "goods_storage", label: "库存", ckbox: false },
        { key: "goods_price", label: "订货价", ckbox: false },
      ],
      // { key:"oenatureplace",label:"产地",},
      //     { key:"oenature",label:"配件性质",},
      attrList: [],
      goods_id: "",
      currentPage: 1,
      pageSize: 15,
      totalParts: 0, //搜索配件数
      total: 0, //搜索总条数
      screenList: {},
      searchData: {
        //筛选框选中列表
        city: [],
        oecarbrand: [],
        oenaturebrand: [],
        oesort: [],
        store_name: [],
        st_id: [],
      },
      couditionH: "20px",
      //    互换件相关
      storeVis: false,
      replacePopVis:false,
      buyStoreList: [],
      noInquireList: [],
      disStoreList: [],
      aliasList: [],
      shopGoodsList: [], // 商家分组的数据
    };
  },
  async created() { 
    this.$api.getAnalysisLog("search")
    // 获取品牌别名
    let aliasRes = await this.$api.getBrandAliasName({
      limit: "99",
    });
    this.aliasList = aliasRes.data;

    // 获取所有的平台店铺
    this.disStoreList = await this.$api.getDisAllStore();

    this.disStoreList.push({
      store_id: "1780",
      store_name: "华诺威汽车零部件（上海）股份有限公司",
    }); 
    if (this.$store.state.batchs.length > 0) {
      this.searches();
    } else {
      if (!this.$store.state.searchText) {
        // 获取历史记录
        let res1 =
          (await this.$api.getSearchRecord({
            record_type: 3,
          })) || []; 
        if (res1.length > 0) {
          this.$store.commit("UpDateState", {
            searchText: res1[0].record_name,
          });
        }
      } else {
        this.query(true);
      }
    }
    // 品牌比例列表
    // this.$store.state.ratioBrandList = await
  },

  methods: {
    async openAll(data){  
        // 显示货位
        if(this.$store.state.member_id.indexOf('1780-')!=-1){ 
            let allotion = await this.$http(`${this.$axios.defaults.baseURL.replace("web",'public')}index.php?act=search&op=stockInfo`,{skuId:data.skuId})
            this.goodsAllotion = this.$hnwTools.getAllotion(allotion) 
        }  
        
        this.openAllotion= true
    },
    handleClose(done) {
      done();
    },
    impueNumChange(goods) {
      if (isNaN(goods.goods_num)) {
        this.$nextTick(() => {
          this.$set(goods, "goods_num", 1);
        });
      }
      if (goods.goods_storage > 0 && goods.goods_num > goods.goods_storage) {
        this.$nextTick(() => {
          goods.goods_num = goods.goods_storage;
        });
        return this.$store.commit("UpDateState", { invDia: true });
      }
    },
    postDemand() {
      this.$router.push("/my/demand");
    },
    handleChange(value) {
      // console.log(value);
    },
  
    // 弹出框数据
    async showGoodsInfo(goods) {
      goods.preRou = "找件";
      localStorage.setItem("hnw-web-item-data", JSON.stringify(goods));
      this.$router.push("/find/info");
      
    },

    // 升序降序
    sortChange(type, way, index) {
      this.sort_type = type;
      this.sort_way = way;
      this.sortActive = type + way;

      this.listAttr[index].ckbox = !this.listAttr[index].ckbox;

      this.query();
    },
    cutGroup(type) {
      try {
        if(type=='no'&&this.noInquireList.length==0){
          return 
        }
      } catch (error) {
        
      }
      this.group_type = type;
      if (this.$store.state.batchs.length > 0) {
        this.searches();
      } else {
        this.query();
      }
      let jsarr = {};
      let sharr = {};
      // 处理商家分组
      if (type == "parts") {
        this.shopGoodsList = [];
        this.$set(jsarr, "goods_list", []);
        this.$set(jsarr, "store_info", "华诺威汽车零部件（江苏）有限公司");
        this.$set(sharr, "store_info", "华诺威汽车零部件（上海）股份有限公司");
        this.$set(sharr, "goods_list", []);
        this.storeGoodsList.forEach((item) => {
          item.goods_list.forEach((goods) => {
            goods.store_id=='2197'
              ? jsarr.goods_list.push(goods)
              : sharr.goods_list.push(goods);
          });
        })
        this.shopGoodsList.push(sharr);
        this.shopGoodsList.push(jsarr);
      }
    },
    async exNoInquire(type){
            // 表头
      
        let tHeader = ["生产编码/OE号","订购数量"]
        let filterVal = ["code","number"]
        let filename = "查询失败配件"
        let array = [...this.noInquireList]
        // 如果是导出列表
        
        let msg =""
        try {
            if(type=='all'){
              tHeader = ["OE号","商品名称","生产编码","配件品牌","订货价","订购数量","车型"]
              filterVal = ["oecode","goods_name","sccode","oenaturebrand","goods_price","batch_pur_num","vehicletype"]
              // batch_pur_num
              array = [...this.storeGoodsList[0].goods_list]
              
              console.log("array",array);
              filename = "批量采购列表"
            }
            msg = this.$message({
                message:'正在导出中....请不要离开本页面',
                type: 'warning',
                duration:0
            })
            
            let data = this.formatJson(filterVal,array)
            
            console.log("tHeader",tHeader)
            console.log("data",data)
            export_json_to_excel({
                header:tHeader,
                data,
                filename,
                autoWidth:true,
                bookType:'xlsx'
            })
            msg.close()
        } catch (error) {
          this.$warMsg("导出失败")
          msg.close()
        }
        
        
    },
    formatJson(filterVal, tableData) {
        return tableData.map(v => {

            return filterVal.map(j => {
                    return v[j]
                })
            }
        )
    },
    // 去下一页
    shortCurpageChange(type) {
      if (type == 1) {
        if (this.storeGoodsList[0].curpage > 1) {
          this.storeGoodsList[0].curpage--;
          this.handleStoreCurrentChange(this.storeGoodsList[0]);
        } else {
          // this.$warMsg("已经第一个了")
        }
      } else {
        if (
          this.storeGoodsList[0].curpage <
          this.storeGoodsList[0].total / this.storeGoodsList[0].page
        ) {
          this.storeGoodsList[0].curpage++;
          this.handleStoreCurrentChange(this.storeGoodsList[0]);
        } else {
          // this.$warMsg("已经最后一页了")
        }
      }
    },
    //展开收起筛选
    toggleScreen() {
      this.showCond = !this.showCond;
      this.showScreen = !this.showScreen;
      if (this.showCond) {
        this.showCond = true;
        this.message = "收起筛选";
      } else {
        this.showCond = false;
        this.message = "展开筛选";
      }
    },
  
    toggleCH() {
      this.couditionH = this.couditionH == "auto" ? "20px" : "auto";
    },
    //  弹出框展示数据
    // goPull() {
    //     this.showPull=!this.showPull
    //     this.showAll=!this.showAll
    // },

    //  分页
    async handleSizeChange(val) {
      await this.query();
      scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.query();
    },
    // 店铺商品分页
    async handleStoreSizeChange(store) {
      let res = await this.storeSearch(
        store.curpage,
        store.page,
        store.store_info.store_id
      );

      store.goods_list = res;
      store.goods_list.forEach((goods) => {
        this.$set(goods, "goods_num", 1);
        this.$set(goods, "choose", false);
        goods.goods_price = this.$handlePrice(goods);
        let store = this.disStoreList.find(
          (item) => item.store_id == goods.store_id
        );

        if (store) {
          goods.store_name = store.store_name;
        } else {
          console.log("未知store_id", goods.store_id);
        }
        if(goods.exchange){
           goods.exchange = goods.exchange.filter(item=>item!=goods.oecode)
            this.$set(goods,"replacePopVis",false)
        }else{
          goods.exchange = []
        }
      });
    },
    async handleStoreCurrentChange(store) {
      let res = await this.storeSearch(
        store.curpage,
        store.page,
        store.store_info.store_id
      );

      store.goods_list = res;
      store.goods_list.forEach((goods) => {
        this.$set(goods, "goods_num", 1);
        this.$set(goods, "choose", false);
        goods.goods_price = this.$handlePrice(goods);
        let store = this.disStoreList.find(
          (item) => item.store_id == goods.store_id
        );

        if (store) {
          goods.store_name = store.store_name;
        } else {
          console.log("未知store_id", goods.store_id);
        }
        if(goods.exchange){
           goods.exchange = goods.exchange.filter(item=>item!=goods.oecode)
            this.$set(goods,"replacePopVis",false)
        }else{
          goods.exchange = []
        }
      });
    },
    //头部筛选切换
    activeFilter(type, val, st_id) {
      if (!this.searchData[type].includes(val)) {
        this.searchData[type].push(val);
        if (st_id) {
          this.searchData.st_id.push(st_id);
        }
      } else {
        this.searchData[type] = this.searchData[type].filter(
          (scr) => scr != val
        );
        if (st_id) {
          this.searchData.st_id = this.searchData.st_id.filter(
            (st_id1) => st_id1 != st_id
          );
        }
      }

      this.query();
    },
    //头部筛选取消选择
    clearActiveFilter(type) {
      if (type == "pitchScreen") {
        for (let key in this.searchData) {
          this.searchData[key] = [];
        }

        return this.query(true);
      }
      if (type == "store") {
        this.searchData.store_name = [];
        this.searchData.st_id = [];
      }
      this.searchData[type] = [];
      this.query();
    },

    
    async showChangeParts(epcPart) {
      try {
       
        this.storeGoodsList.forEach(item=>{
          item.goods_list.forEach(goods=>{
            goods.replacePopVis = false
          })
        })
      } catch (error) {
        console.log(error);
        
      }
      if(epcPart.exchange&&epcPart.exchange.length>0){
        let oem =  epcPart.exchange.join(",")
        this.buyStoreList = []
        let res = await this.$api.getBatchSearch({
          oem
        })
        console.log("epcPart",epcPart)
        console.log("res",res)
       
        if(res&&res.length>0){
          let addStr = ''
          res.forEach(goods=>{
            if(!addStr.includes(goods.oecode)){
              this.buyStoreList.push({
                goods_id:goods.goods_id,
                oecode:goods.oecode
              })
              addStr += goods.oecode+","
            }
          })
        }
        epcPart.exchange.forEach(oecode=>{
          let has = this.buyStoreList.some(goods=>goods.oecode==oecode)
          if(!has){
            this.buyStoreList.push({
              oecode
            })
          }
          
        })
        
        epcPart.replacePopVis = true
      }
     
    },
    joinCart(carItem, index, childIndex) { 
      // 
      const tidySubPart = async () => {
        let carMessage = {
          goods_id: carItem.goods_id,
          sccode: carItem.sccode,
          oecode: carItem.oecode,
          goods_name: carItem.goods_name,
          goods_price: carItem.goods_price,
          goods_num: carItem.goods_num,
          goods_storage: carItem.goods_storage,
          store_id: carItem.store_id,
          store_name: carItem.store_name,
          cart_type: "1"
        };

        let res =  await this.$api.joinCart([carMessage]);
        //更新记录中 已添加X件 字段
        if(res){
          this.storeGoodsList[index].goods_list[childIndex].cartNum = Number(carItem.cartNum) + Number(carItem.goods_num);
        }
      };

      if (this.$isCompany()) {
        if (this.$store.state.zero_buy != 1) {
          if (carItem.goods_storage < 1) {
            // return this.$store.commit('UpDateState',{invDia:true})
          } else if (
            carItem.goods_num + Number(carItem.cartNum) >
            carItem.goods_storage
          ) {
            return this.$store.commit("UpDateState", { invDia: true });
          }
        }
        // 预购单已添加提示
        if (carItem.goods_storage < 1 && Number(carItem.cartNum) > 0) {
          this.$affMsg("当前预购单已有该商品，是否继续添加?", tidySubPart);
        } else {
          tidySubPart();
        }
      }

    },
    async joinCartAll() {
      let cart = [];

      if (!this.storeGoodsList[0].goods_list) {
        return this.$warMsg("没有任何商品");
      }
      this.storeGoodsList.forEach((item) => {
        item.goods_list.forEach((goods) => {
          cart.push({
            goods_id: goods.goods_id,
            sccode: goods.sccode,
            oecode: goods.oecode,
            goods_name: goods.goods_name,
            goods_price: goods.goods_price,
            goods_num: goods.goods_num,
            goods_storage: goods.goods_storage,
            buyer_id: "",
            order_name: "",
            member_id: "",
            buyer_name: "",
            store_id: goods.store_id,
            store_name: goods.store_name,
            cart_type: "1",
            setlist_id: "",
            groupbuy_id: "",
            promotion_id: "",
          });
        });
      });

      let res = await this.$api.joinCart(cart);
      if (res) {
      }
    },
    async replaceJoinCart(carItem) {
      this.storeVis = false;
      // if(this.$store.state.zero_buy!=1){
      //     if(carItem.goods_storage<1){
      //         return this.$store.commit('UpDateState',{invDia:true})
      //     }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
      //         return this.$store.commit('UpDateState',{invDia:true})
      //     }
      // }
      let carMessage = {
        goods_id: carItem.goods_id,
        sccode: carItem.sccode,
        oecode: carItem.oecode,
        goods_name: carItem.goods_name,
        goods_price: carItem.goods_price,
        goods_num: carItem.goods_num,
        goods_storage: carItem.goods_storage,
        store_id: carItem.store_id,
        store_name: carItem.store_name,
        cart_type: "1",
      };

      await this.$api.joinCart([carMessage]);
    },
    async storeSearch(curpage, page, store_id) {
      return await this.$api.getStoreSearch({
        store_id,
        member_id: this.$store.state.member_id,
        curpage: curpage,
        page: page,
        group_type: this.group_type,
        sort_type: this.sort_type,
        sort_way: this.sort_way,
        search: this.$store.state.searchText,
        storage_type: this.showZero ? "ok" : "",
        type: screen ? "screen" : "",
        oenaturebrand: this.searchData.oenaturebrand.join(","),
        oesort: this.searchData.oesort.join(","),
      });
    },

    /*
      调用接口返回搜索结果
      * */
    toSearch(goods){
     
      this.$store.commit("UpDateState", {
        searchText: goods.oecode
      });
      this.storeVis = false
      goods.replacePopVis = false
    },
    async query(screen) {
      
      
      if (screen) {
        this.noInquireList = [];
        this.group_type = "";
      }

      let search = "";
      search = this.$store.state.searchText;
      search = search.replace(this.$hnwTools.spec, "");
      search = search.trim();
      let sort_self = "";
      if (this.sort_self) {
        if (this.$store.state.ratioBrandList) {
          this.$store.state.ratioBrandList.forEach((item) => {
            sort_self += item.name + ",";
          });
          sort_self = sort_self.substring(0, sort_self.length - 1);
        }
      } else {
        sort_self = "";
      }
      
      this.$store.commit("toggleLoding", true);
      let res = "";
      let isHhj = false
      if (search != "") {
        let brandAlis = this.aliasList.find((item) =>
          item.like_name.includes(search)
        )
        let searchBrands = ""
        if(!this.$store.getters.notLimitBrands){
          searchBrands = this.$store.state.brands
        }else{
          searchBrands = this.searchData.oenaturebrand.join(",")
        }
        let sort_type = this.sort_type
        
        if(this.$store.state.is_sh!=1&&sort_type=='goods_storage'){
          sort_type = "goods_stcids"
        }
        
        // sort_type
        if (brandAlis) { 
          search = brandAlis.brand;
          res = await this.$api.getFindList({
            store_id: this.$store.state.store_id,
            member_id: this.$store.state.member_id,
            store_curpage: this.currentPage,
            store_page: this.pageSize,
            group_type: this.group_type,
            sort_type,
            sort_way: this.sort_way,
            sort_self,
            search,
            storage_type: this.showZero ? "ok" : "",
            type: screen ? "screen" : "",
            oenaturebrand:searchBrands ,
            oesort: this.searchData.oesort.join(","),
            st_id: this.searchData.st_id.join(","),
            shop_type: this.$store.state.shop_type,
          });
        } else { 
          
          res = await this.$api.getFindList({
            store_id: this.$store.state.store_id,
            member_id: this.$store.state.member_id,
            store_curpage: this.currentPage,
            store_page: this.pageSize,
            group_type: this.group_type,
            sort_type,
            sort_way: this.sort_way,
            sort_self,
            search:this.$store.state.searchOesort?"":search,
            storage_type: this.showZero ? "ok" : "",
            type: screen ? "screen" : "",
            oenaturebrand: searchBrands,
            
            oesort: this.$store.state.searchOesort?this.$store.state.searchOesort:this.searchData.oesort.join(","),
            st_id: this.searchData.st_id.join(","),
            shop_type: this.$store.state.shop_type,
          });
          // 搜索非品牌并且存在互换件
          if(res.group&&res.group.length>0){
            isHhj = true
            
            res = await this.$api.getFindList({
                store_id: this.$store.state.store_id,
                member_id: this.$store.state.member_id,
                store_curpage: this.currentPage,
                store_page: this.pageSize,
                group_type: this.group_type,
                sort_type,
                sort_way: this.sort_way,
                sort_self,
                oecode: res.group.join(","),
                storage_type: this.showZero ? "ok" : "",
                type: screen ? "screen" : "",
                oenaturebrand: searchBrands,
                oesort: this.searchData.oesort.join(","),
                st_id: this.searchData.st_id.join(","),
                shop_type: this.$store.state.shop_type,
              });
          }
        }
        
      }
      
      this.$store.commit("toggleLoding", false);
      if (!res || !res.data) {
        if (this.$store.state.searchText) {
          this.$warMsg("未搜索到任何商品");
        }
        return (this.storeGoodsList = []);
      }
      try {
        this.queryGoodsListHandle(res,isHhj)
      } catch (error) {
        console.log("列表错误",error);
        this.storeGoodsList = [[{
          goods_list:[]
        }]];
      }
    },
    queryGoodsListHandle(res,isHhj){
      
      if (res.data.length > 0) {
        res.data.forEach((store) => {
          this.$set(store, "curpage", 1);
          this.$set(store, "page", 15);
          store.goods_list.forEach((goods, index) => {
            if (!goods.self_storage) {
              goods.self_storage = 0;
            }
            if (!goods.cartNum) {
              goods.cartNum = 0;
            }


            if (!goods.self_storage) {
              goods.self_storage = 0;
            }
            this.$set(goods, "goods_num", 1);
            // 设置整箱单价
            if (this.$store.state.ratioBrandList) {
              let obj = this.$store.state.ratioBrandList.find((brand) => {
                if (brand.name == goods.oenaturebrand) {
                  return brand;
                }
              });
              if (obj) {
                this.$set(goods, "hnw_self", true);
                if (goods.spec) {
                  let box = goods.spec.split("/");
                  if (box[1]) {
                    this.$set(goods, "boxNum", box[0]);
                    goods.brand_ratio_price = this.$hnwTools.times(
                      goods.goods_price,
                      obj.ratio
                    );
                  }
                }
              }

              this.$set(goods, "goods_num", 1);
              this.$set(goods, "checked", false);
            }
          });
        });
      }
      this.storeGoodsList = [...res.data];  
     
      
      // 价格体系
      this.storeGoodsList.forEach(async (store) => {
        store.goods_list.forEach((goods) => {
          goods.goods_price = this.$handlePrice(goods);
          let store = this.disStoreList.find(
            (item) => item.store_id == goods.store_id
          );

          if (store) {
            goods.store_name = store.store_name
          } else {
            console.log("未知store_id", goods.store_id)
          }
        });
      });

      // 处理互换件
      this.storeGoodsList.forEach((store) => {
        store.goods_list.forEach((goods) => {
          if(goods.exchange){
             goods.exchange = goods.exchange.filter(item=>item!=goods.oecode)
           
            this.$set(goods,"replacePopVis",false)
          }else{
            goods.exchange = []
          }
          
        });
      });
      // 处理互换件图标
      if (isHhj) {
        
        let goodsList = this.storeGoodsList[0].goods_list;

        goodsList.forEach((goods) => {
          goods.isExchange = true
          
        });
       
      }
      if (screen) {
        this.screenList = res.screen;
      } else if (res.screen1) {
        for (let key in res.screen1) {
          this.screenList[key] = res.screen1[key];
        }
      } else {
        console.log(" 没 用 筛 选 值 ");
      }
      this.total = res.total;
      if (this.$store.state.shop == 1780) {
      
        this.storeGoodsList.forEach(async (goods) => {
          goods.goods_list.forEach((item) => {
            this.$storageCangInfo(item); 
          }); 
        });
      }
    },
    async searches() {
      let oecodes = "";
     
      this.$store.state.batchs.forEach((item) => {
        oecodes += item.search + ",";
      });
      const chesLoading = this.$loading({
        lock: true,
        text: '正在搜索...',
        spinner: 'el-icon-loading', 
      });
      
      setTimeout(() => {
        chesLoading.close();
        this.$store.commit("toggleLoding", false);
      }, 2000);
      oecodes = oecodes.substr(0, oecodes.length - 1);
      let res = await this.$api.getSearches({
        searchs: oecodes,

        store_id: this.$store.state.store_id,
      })

      if( res.data[0].return){
        this.noInquireList  = res.data[0].return.map(item=>{
          let noinqObj = this.$store.state.batchs.find(searItem=>searItem.search==item)
          console.log("noinqObj",noinqObj);
          
          let number = 1
          if(noinqObj){
            number = noinqObj.goods_storage
          }
          return {
            code:item,
            number
          }
        })
      }
    
      if (res.data && res.data.length > 0) {
        if (res.data[0].goods_list && res.data[0].goods_list.length > 0) {
          res.data[0].goods_list.forEach((goods) => {
            this.$set(goods, "cangTip", "");   
            if (this.$store.state.shop == 1780) { 
                this.$storageCangInfo(goods)
            }
          });
        }
      } 

      let arr = res.data[0].goods_list || [];

      arr.forEach((goods) => {
        let obj = this.$store.state.batchs.find((searOe) => {
          let noRpOecode = goods.oecode.replace(/\s/g, "");
          let noRpSccode = goods.sccode.replace(/\s/g, "");
          let noRpEva = goods.evaluation_good_star.replace(/\s/g, "");
          let noRpCount = goods.evaluation_count.replace(/\s/g, "");
          if (
            searOe.search == goods.oecode ||
            goods.sccode == searOe.search ||
            searOe.search == goods.evaluation_good_star ||
            searOe.search == goods.evaluation_count
          ) {
            return goods;
          } else if (
            searOe.search == noRpOecode ||
            noRpSccode == searOe.search ||
            searOe.search == noRpEva ||
            searOe.search == noRpCount
          ) {
            return goods;
          } else {
            return "";
          }
        });

        if (obj) {
          if (Number(obj.goods_storage) > Number(goods.goods_storage)) {
            this.$set(goods, "goods_num", Number(goods.goods_storage));
            this.$set(goods, "batch_pur_num_bey", true);
            this.$set(goods, "batch_pur_num", obj.goods_storage);
          } else {
            this.$set(goods, "batch_pur_num", obj.goods_storage);
            this.$set(goods, "goods_num", obj.goods_storage);
          }
        }else{
          console.log("可能为互换件",goods);
          this.$set(goods, "goods_num",1);
          this.$set(goods, "isExchange",true);
        }
        goods.goods_price = this.$handlePrice(goods);

        let store = this.disStoreList.find(
          (item) => item.store_id == goods.store_id
        );

        if (store) {
          goods.store_name = store.store_name;
        } else {
          console.log("未知store_id", goods.store_id);
        }
        if(goods.exchange){
           goods.exchange = goods.exchange.filter(item=>item!=goods.oecode)
            this.$set(goods,"replacePopVis",false)
        }else{
          goods.exchange = []
        }
        if (this.$store.state.zero_buy != 1) {
          if (goods.cartNum * 1 > goods.goods_storage * 1) {
            goods.cartNum = goods.goods_storage;
          }
        }
      });
      let arr1 = [];
      let arr2 = [];
      arr.forEach((goods) => {
        if (goods.batch_pur_num_bey) {
          arr1.push(goods);
        } else {
          arr2.push(goods);
        }
      });
      res.data[0].goods_list = [...arr1, ...arr2];
      this.storeGoodsList = res.data;
      if (this.storeGoodsList && this.storeGoodsList.length > 0) {
        if (
          this.storeGoodsList[0].goods_list &&
          this.storeGoodsList[0].goods_list.length > 0
        ) {
          this.storeGoodsList[0].goods_list.forEach((goods) => {
            this.$set(goods, "cartNum", 0);
          });
        }
      }
      
      
      
    },
    async readHisList(goods_id) {
      let res = await this.$api.getGoodsRecord(goods_id);
      this.gooodsHisList = res;
      this.hisVis = true;
    },
  },
  watch: {
    
    "$store.state.searchText": {
      deep: true,
      handler(n) {
        this.currentPage = 1;
        this.pageSize = 15;
        this.query(true);
      },
    },
    "$store.state.batchs": {
      deep: true,
      // immediate:true,
      handler(n) {
        if (n.length > 0) {
          this.searches(true);
        }
      },
    },
  },

  computed: {
    pitchScreen() {
      let arr = [];
      let str = "";
      for (let key in this.searchData) {
        if (this.searchData[key] && key != "st_id") {
          this.searchData[key].forEach((item) => {
            str += item + "；";
          });
        }
        str = str.substr(0, str.length - 1);
        if (str) {
          arr.push(str);
        }
        str = "";
      }

      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  border-top: 1px solid #f7f7f7;
  padding-bottom: 10px;
}
.old-list {
  // 弹出框
  background: #fff;
  .hezi {
    ::v-deep .el-dialog__wrapper {
      .el-dialog {
        .el-dialog__header {
          position: relative;
          padding: 10px 20px 20px;
          .el-icon-close:before {
            position: absolute;
            top: -10px;
            right: 0px;
          }
        }
        .el-dialog__body {
          overflow-y: auto;
          height: 500px;
          padding: 0 10px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.w {
  width: 1200px;
  margin: 0 auto;
  .sort {
    position: relative;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sort-left {
      width: 86px;
      font-size: 16px;
      font-weight: bold;
      color: #333;
      height: 20px;
      border-bottom: 2px solid #3E84D6;
      padding-top: 10px;
    }
    // .sort-right {
    //     height: 20px;
    //     text-align: center;

    //     font-size: 14px;
    //     font-weight: 400;
    //     color: #333333;
    //     line-height: 20px;
    //     cursor:pointer;
    // }
    .screen {
      display: flex;
      position: absolute;
      top: 20px;
      right: 0px;
      text-align: right;
    }
  }

  .condition-tabel {
    border: 1px solid #ccc;
    border-bottom: none;
    margin-top: 8px;
    position: relative;
    z-index: 100;
    background: #fff;
    .coudition-group {
      display: flex;
      .label {
        min-width: 80px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .coudition {
        border-bottom: 1px solid #ccc;
        flex: 1;
        display: flex;
        justify-content: space-between;
        min-height: 28px;
        .active {
          color: #3E84D6;
        }
        .coudition-left {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          overflow: hidden;
          padding: 2px 0;
          position: relative;
          flex: 1;

          .coudition-daxiao {
            margin: 5px;
            min-width: 10%;
            border-right: 1px solid #ccc;
            text-align: center;
          }
          .toggle {
            position: absolute;
            bottom: -2px;
            left: 50%;
            i {
              cursor: pointer;
            }
          }
        }
        .pabo10 {
          padding-bottom: 10px;
        }
        .item {
          text-align: center;
          line-height: 12px;
          padding: 0 2px;
          min-width: 5%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          border-right: 1px solid #ccc;
          cursor: pointer;
          margin: 5px;
        }
        .item:last-child {
          border-right: none;
        }
        .item:hover {
          color: #3E84D6;
        }
      }

      .coudition-right {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        span {
          cursor: pointer;
        }
      }
    }
  }
  .top-handle {
    display: flex;
    border-bottom: 2px solid #3E84D6;
    position: relative;
    .group-list {
      display: flex;
      padding: 5px 0 0 0;
      .group-item {
        cursor: pointer;
        line-height: 38px;
        // border: 1px solid #ccc;
        height: 38px;
        padding: 0 30px;
      }
      .noin-item{
        position: relative;
        .ex-btn{
          position: absolute;
          right: -220px;
          top: 4px;
        }
        .ex-btn1{
          right: -110px;
        }
      }
      .active {
        background: #3E84D6;
        // border: 1px solid #3E84D6;
        color: #fff;
        font-weight: bold;
      }
    }
    .short-pag {
      position: absolute;
      top: 5px;
      right: 65px;
      display: flex;
      align-items: center;
      padding-right: 20px;
      .all {
        padding: 0 10px;
      }
      .filterZero {
      }
      > .up-down {
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        div {
          cursor: pointer;
        }
        .notCli {
          cursor: not-allowed;
        }
      }
    }
    .sort-right {
      position: absolute;
      right: 0;
      top: 10px;
      height: 20px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .screen-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .screen-item {
      position: relative;
      padding: 0 10px;
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 60%;
        left: 0;
        top: 30%;
        background: #888;
      }
    }
    .screen-item:first-child {
      &::before {
        width: 0;
      }
    }

    i {
      cursor: pointer;
    }
    i:hover {
      transform: scale(1.2);
      color: #3E84D6;
    }
  }
  // 商品目录
  .product {
    height: 40px;
    background: #c4c9cc;
    // margin-top: 8px;
    ul {
      display: flex;
      // padding-left: 102px;
      box-sizing: border-box;
      li {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #666;
        .ico {
          width: 12px;
          height: 12px;
          img {
            width: 12px;
            height: 12px;
          }
        }

        .active {
          color: #3E84D6;
        }

        
        
      }
    }
    .teble-head{
      .oecode{
        min-width: 260px;
        max-width: 180px;
      }
      .goods_name{
        // min-width: 150px;
        // max-width: 150px;
        min-width: 180px;
        max-width: 180px;
      }
      .sccode{
        min-width: 120px;
        max-width: 130px;
      }
      .oenaturebrand{
        min-width: 120px;
        max-width: 130px;
      }

      .goods_storage{
        width: 150px;
      }
      .goods_price{
        width: 82px;
      }
      .specification{
        width: 90px;
      }
      .numAd{
        width: 114px;
      }
      .handle-box{
        width: 86px;
      }
      .filterZero {
        position: absolute;
        top: 24px;
      }
    }
  }
  .history {
    margin-top: 10px;
    height: 14px;
    text-align: center;
    color: #999;
  }
  // 商品信息
  .available {
    > div {
      height: 48px;
      line-height: 48px;
      padding-left: 20px;
      border-bottom: 1px solid #dadada;
      background: #ffffff;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
      font-size: 16px;
      .red {
        color: #f00;
      }
      .blue {
        color: #50a3f8;
      }
    }
  }
  .shoppingAll {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 20px 0;
      padding-right: 20px;
      > div {
        // width: 100px;
        // height: 40px;
        background-color: #3e84d6;
        color: #fff;
        padding: 0 5px;
        line-height: 40px;
        text-align: center;
        // border: 1px solid #000;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  .part-storeinfo {
    padding: 10px 5px;
    border-bottom: 1px solid #9e9e9e;
    background: #f7f7f7;
    font-weight: bold;
  }
  .cargo {
    display: flex;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
    margin-bottom: 20px;
    > ul {
      width: 100%;
    }
    .case-list {
      &.isAll {
        height: auto;
      }
    }
    .case-list:nth-child(2n) {
      background: #f7f7f7;
    }
    .case {
      padding-bottom: 10px;
      height: 30px;
      line-height: 44px;
      border-bottom: 1px solid #9e9e9e;
      position: relative;
      .company {
        display: inline-block;
        padding: 0 20px 0 17px;
        font-size: 14px;
        color: #333;
        font-weight: 700;
      }
      .phone {
        font-size: 14px;
        color: #666;
        padding-right: 20px;
        img {
          width: 14px;
          height: 14px;
        }
      }
      .filterZero {
        margin-left: 10px;
      }
    }

    .details {
      display: flex;
      // height: 67px;
      // line-height: 55px;
      // border-bottom: 1px solid #ccc;
      position: relative;
      .id-bs{
        position: absolute;
        left: 49%;
        bottom: 10%;
        color: #ccc;
        border: 1px solid #efefef;
        border-radius: 50%;
        width: 70px;
        text-align: center;
        .bordiv{
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 100%;
          transform: scale(1.3);
          border-radius: 50%;
          border: 2px solid #efefef;
        }
        .bordiv1{
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 100%;
          transform: scale(1.1);
          border-radius: 50%;
          border: .5px solid #efefef;
        }
      }
      >div{
        box-sizing: border-box;
      }
      .el-checkbox {
        width: 84px;
        padding-left: 18px;
      }
      .isOe {
        // min-width: 194px;
        // max-width: 82px;
        // width: 180px;
        width: 260px;
        border-right: 1px solid #e3e4ea;
        display: flex;
        justify-content: space-between;
        .isOe-left {
          width: 80%;
          .isOe-top {
            display: flex;
            margin-top: 14px;
            margin-bottom: 10px;
            // margin: 0 0 0 28px;
            margin-left: 10px;
            position: relative;
            .hnw-copy{
                position: absolute;
                right: 36px;
                top: -6px;
            }
            .name {
              width: 48px;
              height: 20px;
              background-color: #3E84D6;
              font-size: 12px;
              text-align: center;
              line-height: 20px;
              color: #fff;
            }
            .number {
              cursor: pointer;
              // min-width: 180px;
              max-width: 100px;
              // max-width: 180px;
              margin-left: 5px;
              margin-top: 2px;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; //溢出不换行
              padding-top: 0;
              color: #333;
              // display: flex;
            }
            .tag-icon {
              display: flex;
              align-items: center;
              transform: scale(0.7);
              justify-content: center;
              width: 20px;
              height: 20px;
              background: $vin-blue;
              color: #fff;
              border-radius: 50%;
              font-size: 12px;
              position: relative;
              top: -8px;
              left: 2px;
            }
          }
          .vehicleModel {
            margin: 0 0 0 10px;
            max-width: 400px;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            font-size: 14px;
            color: #999;
          }
        }
        .isOe-right {
          img {
            width: 38px;
            margin: 16px 5px;
          }
        }
      }

      .productName {
        // min-width: 150px;
        // max-width: 150px;
        width: 180px;
        line-height: 69px;
        border-right: 1px solid #e3e4ea;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .encoding {
        overflow: hidden;
        width: 120px;
        line-height: 69px;
        border-right: 1px solid #e3e4ea;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        .hnw-copy{
            position: absolute;
            right: 20px;
            top: 8px;
        }
      }
      .brand {
        overflow: hidden;
        width: 120px;
        line-height: 69px;
        border-right: 1px solid #e3e4ea;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        .hnw-self-brand {
          position: absolute;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
          color: #ff7519;
        }
      }
      // 自选品牌
      .selfBrand {
        background: #d9ecff;
        border-bottom: 1px solid #ccc;
      }
      .place {
        overflow: hidden;
        width: 120px;
        line-height: 69px;
        border-right: 1px solid #e3e4ea;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .nature {
        overflow: hidden;
        width: 120px;
        border-right: 1px solid #e3e4ea;
        line-height: 69px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .inventory {
        // overflow: hidden;
        width: 150px;
        // line-height: 69px;
        // padding-top: 30px;
        box-sizing: border-box;
        border-right: 1px solid #e3e4ea;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .TheOrderPrice {
        overflow: hidden;
        width: 82px;
        border-right: 1px solid #e3e4ea;
        line-height: 69px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
          position: relative;
          top: 1px;
        }
      }
      .specification {
        overflow: hidden;
        width: 90px;
        border-right: 1px solid #e3e4ea;
        line-height: 69px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .area {
        overflow: hidden;
        width: 120px;
        border-right: 1px solid #e3e4ea;
        line-height: 69px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .numAd {
        width: 114px;
        border-right: 1px solid #e3e4ea;
        line-height: 69px;
        position: relative;
        .el-input-number {
          margin: 0 0px 0 10px;
        }
        .goods-Fcl-tit {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 2px;
          font-size: 12px;
          line-height: 12px;
          width: 120px;
          text-align: center;
          color: #999;
        }
        .red {
          color: red;
        }
      }
      .handle-box {
        position: relative;
        box-sizing: border-box;
        padding: 5px 0;
        width: 86px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        > div {
          height: 20px;
          line-height: 20px;
        }
        .addition {
          font-size: 14px;
          color: #999;
        }
        .replace-btn {
          color: #3E84D6;
          cursor: pointer;
        }
        .add-btn:active{
          transform: scale(1.1);
        }
      }
      .el-input-number {
        width: 100px;
        margin: 0 64px 0 20px;
      }
      .el-button--default {
        width: 80px;
        height: 22px;
        border: 1px solid #3E84D6;
        padding: 0;
        color: #59a8f8;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border-radius: 0px;
      }

      .stock {
        position: absolute;
        right: 160px;
        top: 26px;
        font-size: 14px;
        color: #999;
      }
      // .addition{
      //     position: absolute;
      //     right: 5px;
      //     top: 44px;
      //     height: 15px;
      //     line-height: 15px;
      //     font-size: 14px;
      //     color: #999;
      // }
    }
    .goods-ex-msg {
      display: flex;
      border-top: 1px solid #e3e4ea;
      justify-content: flex-end;
      align-items: center;
      line-height: 30px;
      height: 30px;
      .buyHis {
        cursor: pointer;
        text-align: right;

        padding: 0 5px;
        color: red;
        font-size: 12px;
        > span {
          margin-right: 10px;
          align-items: center;
        }
        .readMore {
          cursor: pointer;
        }
      }
      .store-name {
        padding: 0 5px;
        color: #3E84D6;
      }
    }
    .pagings {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 20px 0;
    }
    
  }
  .paging {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;

    .his-list {
      .list-head {
        display: flex;
        height: 40px;
        align-items: center;
        background: #eeeeee;
        margin: 10px 0;
        padding: 0 8px;
        > div {
          flex: 1;
          text-align: center;
        }
        .col-100 {
          max-width: 100px;
        }
      }
      .list-body {
        .row {
          display: flex;
          padding: 0 8px;
          > div {
            flex: 1;
            padding: 2px 0;
            border-bottom: 1px solid #eee;
            flex: 1;
            text-align: center;
            line-height: 28px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .col-100 {
            max-width: 100px;
          }
        }
      }
    }
  }
  // 互换件
  .store-vis {
    ::v-deep .el-dialog__header{
      padding: 0;
    }
    .el-dialog {
      .hhj-index{
        width: 40px;
      }
      .handle{
        width: 80px;
        text-align: center;
        .btn {
          cursor: pointer;
          color: #3E84D6;
        }
      }
      .oe{
        flex: 1;
        text-align: center;
      }
      .tit {
        padding: 10px;
        background: #3E84D6;
        color: #fff;
        display: flex;
        justify-content: space-between;
        .el-icon-close {
          cursor: pointer;
          color: #fff;
        }
      }
      
      .list-body {
        padding: 10px;
        .list-head {
          display: flex;
          background: #dfdfdf;
          height: 28px;
          line-height: 28px;
          
         
        }
        .list-row {
          display: flex;
          align-items: center;
          border-bottom: 1px solid#ccc;
          > div {
            text-align: center;
          
            padding: 8px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            
          }
        }
      }
    }
  }
  
}
.alltion{
  width: 100%;
  display: flex; 
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #eee;
  >div{
    width: 25%;
    text-align: center; 
  }
}


</style>
