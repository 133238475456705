<template>
<!-- epc 商品列表展示 -->
<div class="Scd-Vin-Goods-List-table" id="list-table">
    <!-- max- -->
    
    <div class="Vin-Goods-List-body" @scroll="bodyScroll" :style="{'height':maxH + 'px'}" id="list-body">
        <div class="vin-list-head-pla">

        </div>
        <div class="vin-list-head" :class="{'has-over':showRpad}" :style="{'left':headL}" >
            <!-- :class="{'copy-col':attr.title=='配件编码'}" :style="{'min-width':attr.width,'max-width':attr.mxw?attr.mxw:''}" -->
            <div v-if="$route.params.vin!='veh'" class="lock-img" >
              
            </div>
            <div class="head-item"  v-for="(attr,index2) in VinAttrs" :class="attr.key" :style="{'width':attr.width,'min-width':attr.width}" :key="index2">
                <span>
                    {{attr.title}}
                </span>
            </div>
        </div>

        <div class="vin-list-body" v-if="showList">
           <!-- epcPart.lock_flag==3|| -->
           

            <div class="vin-list-part-row" 
                v-for="(epcPart,index) in startGoodsList" :key="index" :id="epcPart.pots_number" :class="{'active':epcPart.active,'disabled':(epcPart.lock_flag==0)&&$route.params.vin!='veh','jump-part-row':epcPart.jump_flag==1}"
                @click="rowClick(epcPart)" 
            >
                <div v-if="$route.params.vin!='veh'" class="lock-img" :class="[`lock-img${epcPart.lock_flag}`]">
                    <span v-if="epcPart.lock_flag==0||epcPart.lock_flag==3">
                        <i class="el-icon-close"></i>
                    </span>
                    <span v-else>
                        <img class="duigou" src="../../assets/vin/duigou.png" alt="" srcset="">
                        <!-- <i class="el-icon-check"></i> -->
                    </span>
                </div>
                
                <template >
                     <!-- :class="{'copy-col':attr.title=='配件编码','show-col':attr.title=='型号','pots-number':attr.title=='图号'}" -->
                    <div class="row-col" 
                        :style="{'width':attr.width,'min-width':attr.width,'height':epcPart.rowH}" v-for="(attr,index4) in VinAttrs"
                        
                        :key="index4" 
                        :class="attr.key"
                        @click="copy(attr.title,epcPart[attr.key])"
                    >
                            <div v-if="attr.key=='real_image_flag'">
                                <el-popover
                                    @show="showPartImg(epcPart)"
                                    popper-class="part-img-popover"
                                    placement="bottom"
                                    width="250px"
                                    v-if="epcPart.real_image_flag==1"
                                    trigger="click">
                                    <div class="part-img-box">
                                        <img :src="partImgUrl" alt="" >
                                    </div>
                                    <i slot="reference" class="part-img-icon el-icon-picture" ></i>
                                    
                                </el-popover>
                            </div>
                            <div  v-else-if="attr.key=='show_explain'&&epcPart.descmodel" @click="showPlain(epcPart)">
                                <img src="../../assets/vin/descModel.png" alt="">
                                <!-- <i  class="part-img-icon el-icon-collection-tag" ></i> -->
                            </div>
                            
                            <template v-else>
                                <div v-if="epcPart.jump_flag==1&&attr.key=='part_memo'" class="re-fmid">
                                    <div>
                                        <div v-for="(jumpItem,index) in epcPart.partMemoArr" :key="index"  @click.stop="toRefmid(epcPart,jumpItem,index)">
                                            {{jumpItem}}
                                        </div>
                                    </div>
                                    <div class="icon">
                                        转
                                    </div>
                                    
                                </div>
                                
                                <span v-else v-html="epcPart[attr.key]">
                                    
                                </span>
                                <img class="copy-btn" v-if="attr.title=='配件编码'&&epcPart[attr.key]" src="../../assets/vin/copy.png" alt="">
                                
                            </template>
                                    <!-- {{epcPart[attr.key]}} -->

                            <!-- <el-tooltip class="item" effect="dark" :open-delay="300" :content="epcPart[attr.key]" placement="top" v-else>
                            </el-tooltip>
                             -->

                            <!-- <i class="el-icon-notebook-2 copy-btn"   v-if="$route.params.vin!='veh'&&attr.title=='型号'&&epcPart.sa_code&&showSa_code" src="../../assets/vin/copy.png" alt="">
                            </i> -->
                    </div>
                    
                </template>

            </div>

            <!-- </div> -->
            <div class="vin-part-info-replate">

            </div>
        </div>
        
    </div>
    <!-- 零件详情 -->
    <!--  -->
    <div class="vin-part-info" id="partInfo" v-if="showPartInfo" :style="{'height':infoProportion +'px'}">
        <div class="info-type-list">
            <div class="type-item" :class="{'active':infoModuType==item.type}" v-for="(item,index) in infoTypeList" :key="index" @click="handleClick(item.type)">
                <div>
                    {{item.title}}
                </div>
            </div>
            <div class="add-pur-btn" @click="togglePurVis">
                加采购车
            </div>
        </div>
        <div v-loading="partInfoLoading">
            <template v-if="infoModuType=='pirce'">
                <div class="mode-body price-list">
                    <div class="list-head">
                        
                        <div class="index-num">#</div>
                        <div>车厂</div>
                        <div>采购价(不含税)</div>
                        <div>采购价(含税)</div>
                        <div>销售价(不含税)</div>
                        <div>销售价(含税)</div>
                        <!-- <div>销售价(含税)</div> -->
                    
                    </div>
                    <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
    
                        <div class="list-row" v-for="(priceItem,index) in priceList" :key="index">
                            
                            <div class="index-num">{{index+1}}</div>
                            <div>{{priceItem.brand_name}}</div>
                            
                            <div>{{priceItem.purchase_price}}</div>
                            <div>{{priceItem.purchase_price_tax}}</div>
                            <div>{{priceItem.sale_price}}</div>
                            <div>{{priceItem.sale_price_tax}}</div>
                            <!-- <div>{{priceItem.sale_price_tax}}</div> -->
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="infoModuType=='link'">
                <div class="mode-body link-list">
                    <div class="list-head">
                        <div class="index-num">#</div>
                        <div class="cc-brand">车厂品牌</div>
                        <div class="code">替换码</div>
                        <div class="price">采购价</div>
                        <div class="price">销售价</div>
                        <div>备注</div>
                    
                        <div class="handle">操作</div>
                    </div>
                
                    <div class="list-body" :style="{'height':infoProportion -58 +'px'}">
                        <div class="list-row" v-for="(replace,index) in replaceList" :key="index">
                            <div class="index-num">{{index+1}}</div>
                            <div class="cc-brand">{{replace.brand_name}}</div>
                            <div class="code">{{replace.replace_number}}</div>
                            <div class="price">{{replace.purchase_price}}</div>
                            <div class="price">{{replace.sale_price}}</div>
                            <div>{{replace.remark}}</div>
                            
                            <div class="handle" >
                                <i class="el-icon-shopping-cart-2"  @click="toBuy(replace)" v-if="replace.gysNum&&$store.getters.notLvD"></i>
                            </div>
                        </div>
                    </div>
    
                </div>
            </template>
            <template v-else-if="infoModuType=='veh'">
                <div class="mode-body veh-list">
                    <div class="list-head">
                        <div class="index-num">#</div>
                        <div>品牌名称</div>
                        <div>年款</div>
                        <div>车系名称</div>
                        <div class="veh-mune-name">车型目录名称</div>
                        <div>配件编码</div>
                        <!-- <div class="handle" v-if="queryType=='part'">操作</div>             -->
                    </div>
    
                    <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
                        <div class="list-row" v-for="(veh,index) in vehList" :key="index">
                            <div class="index-num">{{index+1}}</div>
                            <div>{{veh.brand_name}}</div>
                            <div>{{veh.production_year}}</div>
                            <div>{{veh.car_series_name}}</div>
                            <div class="veh-mune-name" :title="veh.series_name">{{veh.series_name}}</div>
                            <div>{{veh.part_number}}</div>
                            <!-- <div class="handle" v-if="queryType=='part'">
                                <span class="btn" @click="readVehInfo(veh)">查看</span>
                            </div> -->
                        </div>
                    </div>
                    
                </div>
            </template>
            <template v-else-if="infoModuType=='store'">
                <div class="store-msg"  >
                    <div class="mode-body store-list">
                        <div class="list-head">
                            <div class="index-num">#</div>
                            <div>是否互换件</div>
                            <div>名称</div>
                            <div>OE号</div>
                            <div>生产编码</div>
                            <div>品牌</div>
                            <div>价格</div>
                            <div>库存</div>
                            <div class="handle">操作</div>
                        </div>
                        <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
                            <div class="list-row" v-for="(goods,index) in storeList" :key="index">
                                <!-- <div>{{goods.store_name}}</div> -->
                                <!-- 简称 -->
                                <div class="index-num">{{index+1}}</div>
                                <div>
                                    {{ goods.isHhj?"是":"否" }}
                                </div>
                                <div>{{goods.goods_name}}</div>
                                <div>{{goods.oecode}}</div>
                                <div>{{goods.sccode}}</div>
                                <div>{{goods.oenaturebrand}}</div>
                                <div v-if="$store.state.shop==1948">详询销售</div>
                                <div v-else>{{goods.goods_price}}</div>
                                <div>
                                    <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                    <span v-else-if="$store.state.shop==1948">有货</span>
                                    <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                </div>
                                <div class="handle" v-if="$store.state.shop==1948">
                                    <span  class="btn" @click="joinCart(goods)"> 
                                        加入购物车 
                                    </span>
                                </div>
                                <div class="handle" v-else>
                                    <span class="btn" :class="{'ygd':goods.goods_storage==0}" @click="joinCart(goods)">
                                        {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </template>
            <template v-else-if="infoModuType=='sonPart'">
                <div class="mode-body price-list">
                    <div class="list-head">
                        <div class="index-num">#</div>
                        <div class="factory">车厂</div>
                        <div>配件编码</div>
                        <div>配件名称</div>
                        <div>备注</div>
                        <div>采购价格(不含税)</div>
                        <div>采购价格(含税)</div>
                        <div>销售价(未含税)</div>
                        <div>销售价(含税)</div>
                        
                    </div>
                    <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
                        
                        <div class="list-row" v-for="(priceItem,index) in kitrList" :key="index">
                            <div class="index-num">{{index+1}}</div>
                            <div class="factory">{{priceItem.brand_name}}</div>
                            <div>{{priceItem.part_number}}</div>
                            <div>{{priceItem.part_name}}</div>
                            <div>{{priceItem.remark}}</div>
                            <div>{{priceItem.purchase_price}}</div>
                            <div>{{priceItem.purchase_price_tax}}</div>
                            <div>{{priceItem.sale_price}}</div>
                            <div>{{priceItem.sale_price_tax}}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="infoModuType=='partImg'">
                <div class="mode-body part-img-list" :style="{'height': infoProportion -58 +'px'}">
                    <div class="img-box" v-for="(img,index) in partImgList" :key="index">
                        <img :src="img" alt="" @click="redBigImg(img)" srcset="">
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div class="store-vis">
        <el-dialog
            :visible.sync="storeVis"
            width="900px"
            :show-close="false"
        >
        
            <div class="tit">
                供应商列表
                <i class="el-icon-close" @click="storeVis=false"></i>
            </div>
            <div class="list-body">
                <div class="list-head">
                    <div>是否互换件</div>
                    <div class="name">名称</div>
                    <div class="oe">OE号</div>
                    <div>品牌</div>
                    <div>价格</div>
                    <div>库存</div>
                    <div class="buy-nums list-rol">
                        购买数量
                    </div>
                    
                    <div>操作</div>
                </div>
                <div class="list-row" v-for="(goods,index) in buyStoreList" :key="index">
                    <!-- <div>{{goods.store_name}}</div> -->
                    <div>{{ goods.isHhj?"是":"否" }}</div>
                    <div class="name">{{goods.goods_name}}</div>
                    <div class="oe">
                        <div class="tag-icon" v-if="goods.isRe" title="互换件">
                                互
                        </div> 
                        {{goods.oecode}}
                    </div>
                    <div>{{goods.oenaturebrand}}</div>
                    <div>￥{{goods.goods_price}}</div>

                    <div>
                        <!-- {{goods.goods_storage}} -->
                        <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                        <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                    </div>
                    <div class="buy-nums list-rol">
                        <el-input-number v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                        <el-input-number v-else @change="impueNumChange(goods)" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                    </div>
                    <div>
                        <span  class="btn" @click="joinCart(goods)">
                            {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                            <!-- 加入购物车  -->
                        </span>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
        title="配件详情"
        :visible.sync="partExplainVis "
        width="60%"
        :show-close="false"
        custom-class="plain-dia"
    >
        <div class="mode-body explain-list">
            <div class="list-head">
                <div class="code">编码</div>
                <div>名称</div>
            </div>
            <div class="list-body">

                <div class="list-row" v-for="(plain,index) in explainList" :key="index">
                    <div class="code">{{plain.code}}</div>
                    <div>{{plain.name}}</div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="partExplainVis = false">关闭</el-button>
        </span>
    </el-dialog>
    <EpcPurFrom ref="EpcPurFrom"></EpcPurFrom>
</div>
</template>

<script>

export default {
    data(){
        return{
            VinAttrs:[
                // 
                {
                    title:"图号",
                    key:"pots_number",
                    width:"60px"
                },
                // 名词解释
                // {
                //     title:"名词解释",
                //     key:"name_explain",
                //     width:"60px"
                // },
                // display_part_number
                {
                    title:"配件编码",
                    key:"part_number",
                    flex:1,
                    width:"140px"
                },
                {
                    title:"",
                    key:"real_image_flag",
                    initKey:"real_image_flag",
                    flex:1,
                    width:"24px"
                },
                {
                    title:"配件名称",
                    key:'part_name',
                    initKey:"part_name",
                    flex:1,
                    width:"180px"
                },
                
                {
                    title:"配件描述",
                    key:"part_memo",
                    initKey:"part_memo",
                    flex:1,
                    width:"120px"
                },
                {
                    title:"用量",
                    key:"part_qty",
                    initKey:"part_qty",
                    flex:1,
                    width:"60px"
                },
                {
                    title:"型号",
                    key:'descmodel',
                    initKey:"descmodel",
                    flex:1,
                    width:"130px"
                },
                {
                    title:"",
                    key:"show_explain",
                    initKey:"show_explain",
                    flex:1,
                    width:"24px"
                },
                {
                    title:"开始年份",
                    key:"begin_year",
                    width:"80px"
                },
                {
                    title:"终止年份",
                    key:"end_year",
                    width:"80px"
                },
            ],
            partInfoLoading:false,
            //  {
            //         title:"参考价格",
            //         key:"price",
            //         width:"80px"
            //     },
            showList:false,
            showPartInfo:false,
            headL:"0",
            handleT:"0",
            storeVis:false,
            buyStoreList:[
               
            ],
            infoTypeList:[
                {
                    title:"供应商",
                    type:"store"
                },
                {
                    title:"4S参考价",
                    type:"pirce"
                },
                {
                    title:"通用替换件",
                    type:"link"
                },
                {
                    title:"适用车型",
                    type:"veh"
                },
                {
                    title:"子配件",
                    type:"sonPart"
                },
                {
                    title:"配件实物图",
                    type:"partImg"
                },
            ],
            maxH:"",
            startGoodsList:[],
            exhiList:[],
            navTop:0,
            infoModuType:"store",
            priceList:[],
            replaceList:[],
            vehList:[],
            storeList:[],
            kitrList:[],
            partImgList:[],
            actiPart:{

            },
            dialogVisible:false,
            dialogImageUrl:"",
            infoProportion:114,
            partImgUrl:"",
            explainList:[],
            partExplainVis:false,
            showRpad:1000,
            purCartVis:false
        }
    },
    methods:{

        async toBuy(epcPart){
            
            this.storeVis = true
            
                // this.$set(item,'goods_num',1)
                // 价格体系
            this.buyStoreList = epcPart.storeList
            this.buyStoreList.forEach(item=>{
                item.goods_price = this.$handlePrice(item)
                this.$set(item,'goods_num',1)
            })
            // 分仓
            let indexRes = []
			let houseInfo = []
			if(this.$store.state.shop==1780){
                // this.buyStoreList = this.buyStoreList.filter(item => item.house_info!=null)
				// Cang 
                this.buyStoreList.forEach((goods) => {
                    this.$storageCangInfo(goods)
                }); 
			}
        },
        toggleH(){
            // this.showPartInfo = !this.showPartInfo
            // this.infoProportion = this.infoProportion==0?267:0
            // this.infoProportion = this.infoProportion==0?153:0
            this.infoProportion = this.infoProportion==114?0:114
        },
        toRefmid(part,jumpitem,tapIndex){ 
         
            this.$emit("showRefmid",{
                ...part,
                jumpitem,
                tapIndex
            })
            // this.$emit("showRefmid",{
            //     ...part,
            //     showIndex:index,
                
            // })
        },
        async showPlain(part){
            let vin = ""
            if(this.$route.params.vin!='veh'){
                vin = this.$route.params.vin
            }
            let res =  await this.$apiScd.getPartPlainByid({
                manufacturer_id:this.manufacturer_id,
                vin,
                part_detail_id:part.part_detail_id
            })
            if(!res||res.length==0){
                this.$warMsg("未查到相关信息")
            }else{
                this.explainList = res
                this.partExplainVis = true
            }
        },
        showPartImg(part){
            this.partImgUrl = ""
            this.$nextTick(async ()=>{
                let res = await this.$apiScd.getPartImage({
                    manufacturer_id:this.manufacturer_id,
                    part_number:part.part_number
                })
                this.partImgUrl = res[0]
            })
        },
        redBigImg(url){
            this.dialogImageUrl = url;
            this.dialogVisible  = true
        },
        copy(title,text){
            if(title=='配件编码'){
                
                // textareaEl.setAttribute('readonly', 'readonly'); // 防止手机上弹出软键盘
                if(text){

                    var textareaEl = document.createElement('textarea');
                    textareaEl.value = text;
                    document.body.appendChild(textareaEl);
                    textareaEl.select();
                    var res = document.execCommand('copy');
                    
                    document.body.removeChild(textareaEl);
                    this.$suMsg("复制成功")
                    return res;
                }
            }else{

            }
        },
        async joinCart(carItem){
            // this.storeVis = false
            if(this.$store.state.shop==1948&&carItem.goods_storage==0){
                carItem.goods_storage=1
            }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num,
                goods_storage:carItem.goods_storage,
                store_id:carItem.store_id,
                store_name:carItem.store_name,
                cart_type:'1'
            }
            
            await this.$api.joinCart([carMessage])
            
            // carItem.preRou = "找件"
            // localStorage.setItem("hnw-web-item-data",JSON.stringify(carItem))
            // let link = location.href.split("#")[0]
            // window.open(link + '#/find/info') 
        },
        impueNumChange(goods) {
            if(isNaN(goods.goods_num)){
                this.$nextTick(()=>{
					this.$set(goods,"goods_num",1)
				})
            }
            if(this.$store.state.shop!=1948){
                if(goods.goods_num>goods.goods_storage){
                    this.$nextTick(()=>{
                        goods.goods_num = goods.goods_storage
                    })
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            
        },
        // 切换类型
        async handleClick(type) {
            this.partInfoLoading = true
            if(type){
                this.infoModuType = type
            } 
            if(this.infoModuType=="pirce"){
                // 获取价格
                await this.getPrice()
            }else if(this.infoModuType=="link"){
                // 获取替换件
                await this.getPartReplace()
            }else if(this.infoModuType=="veh"){
                // 获取适配车型
                await this.getPartVeh()
            }else if(this.infoModuType=="store"){
                await this.getStoreList()
            }else if(this.infoModuType=="sonPart"){
                await this.getKitrList()
            }else if(this.infoModuType=="partImg"){
                await this.getPartImage()
            }
            this.partInfoLoading = false
            this.showPartInfo = true
        },
        async togglePurVis(){
            
            this.$set(this.actiPart,"models",this.carInfo.vehicle_name)
            let resprice = await this.$apiScd.getPartPrice({
                manufacturer_id:this.carInfo.manufacturer_id,
                part_number:this.actiPart.part_number,
            })
            let priceList = resprice.content
            if(priceList){
                let priceInfo = priceList.find(item=>item.brand_name==this.carInfo.brand_name)

                if(priceInfo){
                    this.$set(this.actiPart,"goods_price",priceInfo.purchase_price_tax)
                    this.$set(this.actiPart,"purchase_price",priceInfo.purchase_price_tax)
                }else{
                    this.$set(this.actiPart,"goods_price",priceList[0].purchase_price_tax)
                    this.$set(this.actiPart,"purchase_price",priceList[0].purchase_price_tax)
                }
                
            }
            this.$refs.EpcPurFrom.actiPart = JSON.parse(JSON.stringify(this.actiPart))
            this.$refs.EpcPurFrom.purCartVis = true
           
        },
        
        async getPrice(){ 
            if(this.actiPart.part_number){
                let res = await this.$apiScd.getPartPrice({
                    manufacturer_id:this.manufacturer_id,
                    part_number:this.actiPart.part_number,
                })
                
                this.priceList = res.content 
            }
            
            // 根据二级车厂给出对应采购价
            // this.priceList.find(item=>item.)
        },
        async getPartReplace(){ 
            if(this.actiPart.part_number){
                let res = await this.$apiScd.getHnwPartReplace({
                    manufacturer_id:this.manufacturer_id,
                    part_number:this.actiPart.part_number
                })
                
                this.replaceList = res.content
                if(this.replaceList){
                    let pid_list = this.replaceList.map(item=>item.replace_number)
                    if(pid_list.length>0){
                        let gysList = await this.$api.getGysNum({
                            oeList:pid_list,
                            for_branch:this.$store.state.for_branch,
                            shop:this.$store.state.shop
                        })
                        
                        gysList.forEach(item=>{
                            if(item.data.length>0){
                                this.replaceList.forEach(part=>{
                                    if(part.replace_number==item.oecode){
                                        part.gysNum = item.data.length
                                        part.storeList = item.data
                                    }
                                }) 
                            }
                            
                        })
                    }
                }
            }
             
        },
        async getPartVeh(){
            let res = await this.$apiScd.getPartVeh({
                manufacturer_id:this.manufacturer_id,
                part_number:this.actiPart.part_number
            })
            this.vehList = res
            this.brandList = []
        },
        async getStoreList(){
            let res = await this.$api.getDataCenterRepalce({
                oecode:this.actiPart.part_number
            })
            if(res){
                res = await this.$api.getBatchSearch({
                  oem:res.join()
                })
            }else{
                res = await this.$api.getBatchSearch({
                  oem:this.actiPart.part_number
                })
            }
          
            if(res&&res.length>0){
                res.forEach(goods=>{
                    goods.goods_price =  this.$handlePrice(goods)
                    this.$storageCangInfo(goods)
                    if(goods.oecode!=this.actiPart.part_number){
                        this.$set(goods,"isHhj",true)
                    }
                })
              this.storeList = res
            }else{
                this.storeList = []
            }
            
        },
        async getKitrList(){
            let res = await this.$apiScd.getPartKitr({
                manufacturer_id:this.manufacturer_id,
                part_number:this.actiPart.part_number
            })
            this.kitrList = res
        },
        async getPartImage(){
            let res = await this.$apiScd.getPartImage({
                manufacturer_id:this.manufacturer_id,
                part_number:this.actiPart.part_number
            })
           
            this.partImgList = res
        },
        bodyScroll(e){
           
            this.headL = -e.target.scrollLeft +"px"
            this.handleT = -e.target.scrollTop +"px"
        },
        readGoodsInfo(epcPart){
            
            if(!epcPart.pid){
                return this.$warMsg("")
            }
            this.$router.push(`/catalog/partinfo/${epcPart.pid}/${this.brandCode}/1`)
            this.$store.commit("navPush",{
                label:epcPart.part_name+"详情",
                route:`/catalog/partinfo/${epcPart.pid}/${this.brandCode}/1`
            })  
            
        },
        rowClick(epcPart){
            console.log("epcPart",epcPart)
            this.startGoodsList.forEach(itemItem=>{
                itemItem.active= false
            })
            this.$set(epcPart,"active",true)
            this.actiPart = epcPart
            // 加采购车赋初始值
            this.$set(this.actiPart,"goods_oecode",this.actiPart.part_number)

            this.$set(this.actiPart,"goods_name",this.actiPart.part_name.replace(/<\/br>/g,""))
            this.$set(this.actiPart,"goods_number",1)
            this.$set(this.actiPart,"goods_brand","")
            this.$set(this.actiPart,"supplier","")
            this.$set(this.actiPart,"data",this.$route.params.vin!="veh"?this.$route.params.vin:"")
            
            // this.actiPart.models = ""
            if(epcPart.part_number){
                this.$emit("rowClick",epcPart.pots_number)
            }else{
                
            }
            this.handleClick()
        },
        // 是否有滚动条
        getHasOver(){
            let lisbody = document.querySelector(".vin-list-body") 
            this.showRpad = lisbody.clientHeight>this.maxH
            
        },
        changePart(part_number){
            let part = this.startGoodsList.find(part1=>part1.part_number==part_number)
            this.$set(part,"active",true) 
        }
    },
    async created(){
       
        // this.maxH = (document.documentElement.clientHeight -210)*0.6
        // this.infoProportion = (document.documentElement.clientHeight -210)*0.4 - 5

        this.maxH = (document.documentElement.clientHeight -210)*0.7
        this.infoProportion = (document.documentElement.clientHeight -210)*0.3 - 5
        
    },
    mounted(){
        this.$nextTick(() => {
            this.startGoodsList.forEach(item => {
                if(item.part_number==this.partdatas.part_number){
                    item.active = true
                    this.rowClick(item)
                }else{
                    item.active = false
                }
            })
        })
    },
    watch:{
        listFilter:{
            immediate:true,
            handler(n){   
                if(n){
                    this.startGoodsList =  this.goodsList.filter(item=>{
                        return item.lock_flag == 1 || item.lock_flag == 2 
                        // return item.lock_flag !=0 
                    })
                }else{
                    this.startGoodsList = JSON.parse(JSON.stringify(this.goodsList))
                }

                this.showList = true
                this.$nextTick(()=>{
                    this.getHasOver()
                    let part = {}
                    if(this.activePnum){ 
                        part = this.startGoodsList.find(item=>item.pots_number==this.activePnum&&item.part_number==this.activeCode)
                        let dom = document.getElementById(this.activePnum)
                        let boxDom = document.getElementById("list-body")
                        if(dom){
                            boxDom.scrollTo(0,dom.offsetTop-40)
                        }

                    }else{
                        part = this.startGoodsList.find(item=>item.part_number) 
                    } 
                    if(part){
                        this.rowClick(part)
                    } 
                })
            }
        },
        activePnum:{
            handler(n){ 
                this.$nextTick(()=>{
                    if(this.startGoodsList.find(item=>item.pots_number==n)){
                        let dom = document.getElementById(n)
                        let boxDom = document.getElementById("list-body")
                        if(dom){
                            // boxDom.scrollTo(0,dom.offsetTop-32)
                            boxDom.scrollTo(0,dom.offsetTop-40)
                        }
                    }
                    this.startGoodsList.forEach(epcPart=>{
                        if(epcPart.pots_number==n){
                            this.$set(epcPart,"active",true)
                        }else{
                            this.$set(epcPart,"active",false)
                        }
                    })
                    
                })
            }
        
        }
    },
    
    props:{
        partdatas:{
            require:true
        },
        partInfo:{
            require:true
        },
        goodsList:{
            required:true
        },
        showSa_code:{
            default:true
        },
        carInfo:{
            required:true,
            default(){
                return{

                }
            }
        }, 
        activePnum:"",
        listFilter:true,
        manufacturer_id:"",
        activeCode:"",
        showRefm:{
            default:true
        }
    }
}
</script>

<style lang="scss" scoped>
$head:32px;
.Scd-Vin-Goods-List-table{
    position: relative;
    overflow: hidden;
    // margin-left: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .toggle-box{
        position: absolute;
        right: 0;
        // top: 6px;
        bottom: 250px;
        border-radius: 10px;
        // height: 50px;
        // width: 50px;
        height: 42px;
        width: 42px;
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        background-color: #3158C6;
        color: #fff;
        cursor: pointer;
        z-index: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        >div{
            font-weight: bold;
        }
    }
    .Vin-Goods-List-body{    
        background: #fff;
        // max-height: 712px;
        // max-height: 400px;
        box-sizing: border-box;
        border-bottom: 4px solid #eee;
        overflow: auto;
        border: 2px solid #a9bfdd;
        border-radius: 10px;
        .vin-list-head{
            position: absolute;
            z-index: 2;
            top: 0;
            font-weight: 700;
          
            display: flex;
            background: $scdTaBack;
            color: $scdTaCol;
            height: $head;
            line-height: $head;
            text-align: center;
            justify-content: flex-start;
            width: 0;
            min-width: 100%;
            
            box-sizing: border-box;
            border: 2px solid $scdLine;
            .head-item{
                background: $scdTaBack;
                position: relative;
                &::after{
                    position: absolute;
                	content: '';
                	right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                	width: 1px;
                	height: 20px;
                	background: #becde4;
                }
                // border-right: 1px solid #becde4;
            }
            .real_image_flag{
                &::after{
                    width: 0;
                }
            }
            .show_explain{
                &::after{
                    width: 0;
                }
                
            }
            .lock-img{
                min-width: 14px;
                height: $head;
                width: 14px;
            }
            .part_name{
                flex: 1;
            }
            .part_number{
                flex: 1;
            }
            .part_memo{
                flex: 1;
            }
            .pots_number{
                text-align: center;
            }
        }
        .has-over{
            padding-right: 14px;
        }
        .vin-list-head-pla{
            height: $head;
        }
        .vin-list-body{
            position: relative;
            display: flex;
            flex-direction: column;
            .vin-list-part-row{
                display: flex;
                // align-items: center;
                align-items:stretch;
                color: #666666;
                font-weight: 700;
                flex: 1;
                // border-bottom: 1px solid #ccc;
                .lock-img{
                   color: #fff;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   text-align: center;
                   padding: 0;
                   overflow: hidden;
                   width: 14px;
                   min-width: 14px;
                }
                .lock-img1{
                    background: $scdGreen;
                }
                .lock-img2{
                    background: #fdc356;
                }
                .lock-img0{
                    background: #aaa5a5;
                    i{
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
                .lock-img3{
                    background: #aaa5a5;
                }
                .duigou{
                    width: 15px;
                }
                >div{
                    // padding: 3px 2px !important;
                    padding: 3px 0;
                }
                .row-col{
                    // min-height: 20px;
                    // height: 100%;
                    
                    box-sizing: border-box;
                    cursor: pointer;
                    // height: 40px;
                    // line-height: 40px;
                    // border-bottom: 1px solid #ccc;
                    // overflow: hidden;
                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                    position: relative;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: left;
                    border-bottom: 1px solid #ccc;
                   .copy-btn{
                       transform: translate(2px,-4px);
                   }
                   .part-img-icon{
                        font-size: 20px;
                        color: #86dff7;
                   }
                   .el-icon-collection-tag{
                        font-weight: 700;
                        font-size: 18px;
                        color: #409eff;
                   }
                }
                .re-fmid{
                    color: #409EFF;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .icon{
                        box-sizing: border-box;
                        height: 24px;
                        line-height: 24px;
                        margin-top: 5px;
                        padding: 0 2px;
                        border-radius: 2px;
                        color: #fff;
                        margin-left: 2px;
                        background: red;
                    }
                }
                .part_name{
                    flex: 1;
                }
                .part_number{
                    flex: 1;
                }
                .part_memo{
                    flex: 1;
                }
                
                .show-col{
                    position: relative;
                    .copy-btn{
                        position: absolute;
                        top: 5px;
                        right: 2px;
                    }
                }
                .pots_number{
                    text-align: center;
                }
                .show_explain{
                    img{
                        width: 14px;
                    }
                }
                .part_qty{
                    text-align: center;
                }
                .button-box{
                    margin-left: 10px;
                    .buy-button{
                        cursor: pointer;
                        color: #409EFF;
                    }
                }
                
            }
            .jump-part-row{
                .part_name{
                    text-align: right;
                }
                .part_memo{
                    text-align: left;
                }
            }
            .vin-list-row:hover{
                background-color: #e6f7ff;
            }
            .disabled{
                background: #fff;
                >div{
                    
                    color: #aaa;
                }
            }
            // .vin-list-pots_number-row{

            // }
            .active{
                // border: 2px solid #1890ff !important;
                // >div{
                //     border-top: 2px solid #1890ff !important;
                //     border-bottom: 2px solid #1890ff !important;
                // }
                >div{
                    background: $scdGreen;
                    color: #fff;
                }
            }
            .vin-part-info-replate{
                // height: 200px;
            }
        }
        
    }
    .vin-part-info{
        
        overflow: hidden;
       
        background: #fff;
        width: 100%;
        bottom: 0;
        margin-top: 5px;
        border: 2px solid$scdLine;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
      
        .el-tabs{
            .el-tabs__header{
                padding-left: 10px;
                margin: 0;
                .el-tabs__item{
                    height: 28px;
                    line-height: 28px;
                }
            }
        }
        .info-type-list{
            display: flex;
            padding-right: 10px;
            border-radius: 4px;
            position: relative;
            .type-item{
                padding: 0 5px;
                height: 28px;
                line-height: 28px;
                font-size: 16px;
                margin: 0 5px;
                background: #fff;
                cursor: pointer;
                border: 1px solid #b6c8e1;
                border-top: none;
                transform: skewX(15deg);
                >div{
                    transform: skewX(-15deg);
                }
            }
            .active{
                background: #36af47;
                color: #fff;
            }
            .add-pur-btn{
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-weight: 700;
                cursor: pointer;
                padding: 0 10px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                background: #16b2ec;
                color: #fff;
                font-size: 16px;
                border-radius: 6px;
            }
        }

        .mode-body{
            height: 100%;
            .index-num{
                min-width: 40px;
                max-width: 40px;
            }
            .list-head{
                display: flex;
                height: 20px;
                align-items: center;
                // background: #FAFAFA;
                background: #FAFAFA;
                text-align: center;
                color: $scdTaCol;
                box-sizing: border-box;
                >div{
                    flex: 1;
                    border-right: 1px solid $scdLine;
                }
                >div:first-child{
                    // text-align: left;
                    // padding-left: 50px;
                }
                .factory{
                    width: 100px;
                }
            }
            .list-body{
                overflow: auto;
                // max-height: 207px;
                .list-row{
                    display: flex;
                    align-items: center;
                    min-height: 34px;
                    // border-bottom: 1px solid #ccc;
                    border-bottom: 1px solid #eee;
                    text-align: center;
                    font-size: 16px;
                    >div{
                        flex: 1;
                        line-height: 19px;
                        // text-align: center;
                    }
                    >div:first-child{
                        // text-align: left;
                        // padding-left: 20px;
                    }
                    .factory{
                        width: 100px;
                    }
                }
                .list-row:last-child{
                    border:none;
                }
                .handle{
                    display: flex;
                    justify-content: center;
                    .btn{
                        width: 86px;
                        height: 26px;
                        line-height: 26px;
                        cursor: pointer;
                        background: #fff;
                        color: #333;
                    }
                    .ygd{
                        color: red;
                    }
                }
            }
        }
        .link-list{
            .cc-brand{
                min-width: 100px;
                max-width: 100px;
            }
            .code{
                min-width: 140px;
                max-width: 140px;
            }
            .price{
                min-width: 100px;
                max-width: 100px;
            }
            .handle{
                min-width: 80px;
                max-width: 80px;
            }
        }
        .part-img-list{
            display: flex;
            max-height: 240px;
            flex-wrap: wrap;
            overflow: auto;
            .img-box{
                // width: 200px;
                padding: 10px;
                img{
                    // width: 200px;
                    height: 180px;
                    cursor: pointer;
                }
            }
        }

    }
    ::v-deep .el-dialog__header{
        padding: 0;
    }
    ::v-deep .el-dialog__body{
        padding: 0;
        
    }
    .store-vis{
        .el-dialog{
            
            .tit{
                padding: 10px ;
                background: #3E84D6;
                color: #fff;
                display: flex;
                justify-content: space-between;
                .el-icon-close{
                    cursor: pointer;
                    color: #fff;
                }
            }
            .list-body{
                padding: 10px;
                .list-head{
                    display: flex;
                    background: #DFDFDF;
                    height: 28px;
                    line-height: 28px;
                    >div{
                        text-align: center;
                        flex: 1;
                    }
                    .name{
                        min-width: 120px;
                    }
                    .oe{
                        min-width: 140px;
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 10px;
                    }
                    
                }
                .list-row{
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid#ccc;
                    >div{
                        text-align: center;
                        flex: 1;
                        padding: 8px 0 ;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .btn{
                            cursor: pointer;
                            color: #3E84D6;
                        }
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 10px;
                        justify-content: flex-start;
                    }
                    .tag-icon{
                        display: flex;
                        align-items: center;
                        transform: scale(.9);
                        justify-content: center;
                        width: 17px;
                        height: 17px;
                        border: 1px solid #ff781c;
                        border-radius: 50%;
                        color: #ff781c;
                        margin-right: 5px;
                        font-size: 12px;
                    }
                    .buy-nums{
                        .el-input-number{
                            width: 100%;
                        }
                    }
                     .name{
                        min-width: 120px;
                    }
                    .oe{
                        min-width: 140px;
                    }
                }
            }
           
        }
    }
    .part-config-content{
       
        .hint-title{
            padding:10px;
        }
        .config-data-table{
            .table-head{
                background: #fafafa;
                display: flex;
                .config-title{
                    padding: 8px;
                    width: 140px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden; 
                }
                .config-title:last-child{
                    flex:1;
                }
            }
            .table-content{
                .config-level{
                    .sa-family{
                        background: #eee;
                        padding: 8px;
                    }
                    .config-son-level{
                        display: flex;
                        padding-left: 140px;
                        border-bottom: 1px solid #eee;
                        >div{
                            padding: 8px;
                            width: 140px;
                            box-sizing: border-box;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden; 
                        }
                        >div:last-child{
                            flex:1;
                        }
                    }
                }
            }
        }
    }
    
}
.part-img-popover{
    padding: 0;
    background: red;
    .part-img-box{
        img{
            width: 15.151515rem /* 500/33 */;
        }
    }
}
.veh-list{
    .veh-mune-name{
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
}
::v-deep .plain-dia{
    .el-dialog__header{
        padding: 10px;
        font-weight: 700;
        color: $scdLine;
    }
    .explain-list{
        .list-body{
            border: 1px solid #ccc;
            .list-row{
                    border-bottom: 1px solid #ccc;
            }
        }
        .code{
            min-width: 120px;
            max-width: 120px;
        }
    }
}

</style>