<template>
    <div class="find-list">
        <div class="w">
          
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return{
            isOnline:false
        }
    },
    created(){
        
    },
   
    methods:{
        toApp(){
            console.log(this.content);    
        },
        obAppUserStatus(){
            // 清除localStorage所有的数据
            localStorage.clear();
            // 展开第一个的title
            // this.activeName =  logList[0].title

            // 获取当日15点的时间戳
            function getCurrentTimestampAt1500() {
                // 获取当前日期和时间
                const now = new Date();

                // 提取当前日期的年、月、日
                const year = now.getFullYear();
                const month = now.getMonth(); // 注意：getMonth() 返回的月份是从 0 开始的
                const day = now.getDate();

                // 设置时间为当天的 15:00:00
                const timestampAt1500 = new Date(year, month, day, 15, 0, 0, 0);

                // 获取时间戳（以毫秒为单位）
                const timestamp = timestampAt1500.getTime();

                return timestamp;
            }

            // 调用函数并打印结果
            const timestamp = getCurrentTimestampAt1500();
            console.log("timestamp15",timestamp); // 输出当天 15 点的时间戳（以毫秒为单位）
            

            const userIds = ["1"]
            
            // 设置订阅时间，取值范围为[60,2592000]（单位:秒）。
            const expiry= 60 * 60 * 24;
            // 指定订阅类型为用户在线状态。
            const type = RongIMLib.SubscribeType.ONLINE_STATUS

            RongIMLib.subscribeUserStatus(userIds, type, expiry).then((res) => {
                if (res.code === RongIMLib.ErrorCode.SUCCESS) {
                console.log('订阅成功')
                const Events = RongIMLib.Events
                RongIMLib.addEventListener(Events.SUBSCRIBED_USER_STATUS_CHANGE, (event) => {
                    // 5.10.1 版本开始需根据数据中的 subscribeType 来判断是用户资料还是在线状态。
                    console.log('被订阅者状态变更', event)
                    event.forEach(chat=>{
                        this.isOnline =  chat.details[0].eventValue

                        this.$notify({
                            title: '提示',
                            message:  this.isOnline?'在线':"离线",
                            duration: 3000
                        });
                    })
                })
                
                } else if (res.code === RongIMLib.ErrorCode.RC_BESUBSCRIBED_USERIDS_COUNT_EXCEED_LIMIT) {
                    console.log('用户被订阅达到上限', res.code, res.data)
                } else {
                    console.log('订阅失败', res.code, res.msg)
                }
            })
        },
        
    },
    watch:{
        
    },
    computed:{
        
    }
}
</script>

<style lang="scss" scoped>
   
.find-list{
    background: #fff;
    padding: 200px 0;
    
    .w{
        width: 1200px;
        margin: 0 auto;
    }
    
}

</style>