<template>
<div class="dama-info-msg">
    <div class="info-top" v-if="goods">
        <div class="pop-top" >
            <div class="pop-top-goods_name">
                <div class="goods_name-top">商品名称</div>
                <div class="goods_name-bottom">{{goods.goods_name}}</div>
            </div>
            <div class="pop-lines"></div>
            <div class="pop-top-oe">
                <div class="goods_name-top">OE号</div>
                <div class="goods_name-bottom">{{goods.oecode}}</div>
            </div>
            <div class="pop-lines"></div>

            <div class="pop-top-parts">
                <div class="goods_name-top">配件品牌</div>
                <div class="goods_name-bottom">{{goods.oenaturebrand}}</div>
            </div>
            <div class="pop-lines"></div>

            <div class="pop-top-goods_yuanc">
                <div class="goods_name-top">生产码</div>
                <!-- goods.factoryname -->
                <div class="goods_name-bottom">{{goods.sccode}}</div>
            </div>
            <img class="union-img" src="../assets/union/lmicon.png" alt="" v-if="$route.path=='/union/allGoods'">
        </div>
        <div class="pop-line"></div>
        <div class="pop-bottom">
            <div>价格：{{goods.goods_price}}</div>
            <template v-if="$store.state.shop!=1974&&$store.state.shop!=1871">
                <div v-if="$store.state.look_inv==1">库存数量：{{goods.goods_storage}}</div>
                <div v-else>库存数量：{{goods.goods_storage>0?"有货": "无货"}}</div>
            </template>
            
            <div>配件性质：{{goods.oenature || "暂无数据"}}</div>
            <div>汽车品牌：{{goods.oecarbrand || "暂无数据"}}</div>
            <div>配件产地：{{goods.oenatureplace}}</div>
        </div>
    </div>
    <div class="info-msg">
        <div class="info-title">丨适配车型</div>
        <div class="info-adaptive" v-if="Array.isArray(vehList)&&vehList.length>0">

            <div class="info-adaptive-list" v-for="(veh,index) in vehList" :key="index" >
                <el-tooltip class="item" effect="dark" :content="veh.cars_model+veh.year+veh.market" placement="top" :open-delay="300">
                    <div>
                        {{index+1 }}. {{veh.cars_model }} ({{veh.year}}) {{veh.market}} 
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="info-adaptive" v-else>
            <div class="info-adaptive-list" >
                暂无数据
            </div>
        </div>
        <div class="info-title">丨互换件  </div>
        <div class="info-interchange" v-if="Array.isArray(replaceList)&&replaceList.length>0">
            <div class="info-interchange-title">
                <div class="info-interchange-title-brand">品牌</div>
                <div class="info-interchange-title-part">零件号</div>
                <div class="info-interchange-title-remark">备注</div>
            </div>
            
            <div class="info-interchange-title" v-for="(replace,index) in replaceList" :key="index" >
                <div class="info-interchange-title-brands">{{replace.pid[0].brandcn }}</div>
                <div class="info-interchange-title-parts">{{replace.pid[0].pid}}</div>
                <div class="info-interchange-title-remarks">{{replace.pid[0].remark}}</div>
            </div>
        </div>
        <div class="info-interchange" style="padding-left: 20px;" v-else>
            暂无数据
        </div>
        <div class="info-title">丨参考价格</div>
        <div class="info-reference" v-if="Array.isArray(priceList)&&priceList.length>0">
            <div class="info-reference-title">
                <div>零件类型</div>
                <div>厂商</div>
                <div>进价（含税）</div>
                <div>销售价（含税）</div>
                <div>销售价（未含税）</div>
                <div>服务商</div>
            </div>
            <div class="info-reference-title" v-for="(priceItem,index) in priceList" :key="index">
                <div class="color">{{priceItem.parttype}}</div>
                <div class="color">{{priceItem.mill}}</div>
                <div class="color"> <span v-if="priceItem.eot_price">¥</span> {{priceItem.eot_price}}</div>
                <div class="color"><span v-if="priceItem.sale_price">¥</span>{{priceItem.sale_price}}</div>
                <div class="color"><span v-if="priceItem.sale_price1">¥</span>{{priceItem.sale_price1}}</div>
                <div class="color">{{priceItem.supplier}}</div>
            </div>
        </div>
        <div class="info-reference" style="padding-left: 20px;" v-else>
            暂无数据
        </div>
    </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            goods:"",
            showGoods:false,
             priceList:[],
            replaceList:[],
            vehList:[],

        }
    },

    methods:{
       // 弹出框数据
       async showGoodsInfo(){
            // this.dialogVisible = true
            this.goods =  await this.$api.getGoodsInfo({
                goods_id:this.id,
            })
            
            if(this.goods.store_id!=1780){
                this.$handleStorePrice(this.goods)
                if(this.goods.is_chain.includes("3")){
                    this.$hnwTools.formatGoodsKey(this.goods)
                }
            }
            
            
        },
        tabAttrIndex(index){
            this.goodsAttrIndex= index
            if(index==1){
                // this.getPartVeh()
            }else if(index==2){
                // this.getPartReplace()
            }else if(index==3){
                // this.getPrice()
            }
        },
            // async getPrice(){
            //     let res = await this.$apiScd.getPartPrice({
            //         manufacturer_id:12,
            //         part_number:this.goods.oecode,
            //     })
                
            //     this.priceList = res || []
            // },
            // async getPartReplace(){
            //     let res = await this.$apiScd.getPartReplace({
            //         manufacturer_id:12,
            //         part_number:this.goods.oecode
            //     })
                
            //     this.replaceList = res || []
            //     if(this.replaceList){
            //         let pid_list = this.replaceList.map(item=>item.replace_number)
            //         if(pid_list.length>0){
            //             let gysList = await this.$api.getGysNum({
            //                 oeList:pid_list
            //             })
                        
            //             gysList.forEach(item=>{
            //                 if(item.data.length>0){
            //                     this.replaceList.forEach(part=>{
            //                         if(part.replace_number==item.oecode){
            //                             part.gysNum = item.data.length
            //                             part.storeList = item.data
            //                         }
            //                     }) 
            //                 }
                            
            //             })
            //         }
            //     }
            // },
            // // 适配车型
            // async getPartVeh(){
            //     let res = await this.$apiScd.getPartVeh({
            //         manufacturer_id:12,
            //         part_number:this.goods.oecode
            //     })
            //     this.vehList = res || []
                
            // },
      
    },
    created(){
        this.showGoodsInfo()
    },
    watch:{
        id(){
            this.showGoodsInfo()
        }
    },
    props:{
        id:{
            required:true
        }
    }
}
</script>

<style lang="scss">
.dama-info-msg{
    padding-bottom: 10px;
    .info-top{
        width: 940px;
        height: 110px;
        background: #3E84D6;
        border-radius: 6px;
        margin: 10px auto;
        position: relative;
        .pop-top{
            height: 68px;
            display: flex;
            padding-left: 80px;
            
            .pop-lines{
                width: 2px;
                height: 22px;
                background: #D0D0D0;
                opacity: 0.8;
                margin-top: 22px;
            }
            .pop-top-goods_name{
                min-width: 220px;
                text-align: center;
                padding: 0 10px;
                .goods_name-top{
                    font-size: 14px;
                    color: #fff;
                    font-weight: 500;
                    margin: 13px 0 10px 0;
                }
                .goods_name-bottom{
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .pop-top-goods_yuanc{
                width: 230px;
                text-align: center;
                padding: 0 10px;
                .goods_name-top{
                    font-size: 14px;
                    color: #fff;
                    font-weight: 500;
                    margin: 13px 0 10px 0;
                }
                .goods_name-bottom{
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }

            .pop-top-oe{
                width: 220px;
                text-align: center;
                .goods_name-top{
                    font-size: 14px;
                    color: #fff;
                    font-weight: 500;
                    margin: 13px 0 10px 0;
                }
                .goods_name-bottom{
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .pop-top-parts{
                width: 140px;
                text-align: center;
                .goods_name-top{
                    font-size: 14px;
                    color: #fff;
                    font-weight: 500;
                    margin: 13px 0 10px 0;
                }
                .goods_name-bottom{
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
        }
        .pop-bottom{
            height: 40px;
            display: flex;
            padding: 0 50px;
            div{
                flex: 1;
                text-align: center;
                line-height: 40px;
                color: #fff;
            }
        }
        .pop-line{
            border-bottom: 1px solid #EDEDED;
            opacity: 0.2;
        }
        .union-img{
        position: absolute;
        right: 20px;
        bottom: -65px;
        
        }
    }
    .info-msg{
        width: 940px;
        margin: 0 auto;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        border-radius: 6px;
        padding: 0 16px 0 12px;
        box-sizing: border-box;
        .info-title{
            width: 912px;
            height: 32px;
            background: #F5F5F5;
            color: #3E84D6;
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
            padding-left: 10px;
            margin-top: 10px;
        }
        .info-adaptive{
            width: 912px;
            display: flex;
            flex-wrap:wrap;
            .info-adaptive-list{
                padding-left: 17px;
                border-bottom: 1px solid #EDEDED;
                width: 50%;
                height: 32px;
                line-height: 32px;
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            
        }
        .info-interchange{
            width: 912px;
            .info-interchange-title{
                display: flex;
                height: 34px;
                line-height: 32px;
                text-align: center;
                border-bottom: 1px solid #EDEDED;
                .info-interchange-title-brand{
                    width: 152px;
                    font-weight: bold;
                    color: #333333;
                    font-size: 14px;
                }
                .info-interchange-title-part{
                    width: 400px;
                    font-weight: bold;
                    color: #333333;
                    font-size: 14px;
                }
                .info-interchange-title-remark{
                    width: 400px;
                    font-weight: bold;
                    color: #333333;
                    font-size: 14px;
                }
                .info-interchange-title-brands{
                    width: 152px;
                    color: #666;
                    font-size: 14px;
                }
                .info-interchange-title-parts{
                    width: 400px;
                    color: #666;
                    font-size: 14px;
                }
                .info-interchange-title-remarks{
                    width: 400px;
                    color: #666;
                    font-size: 14px;
                        overflow: hidden;/*超出部分隐藏*/
                    text-overflow:ellipsis;/* 超出部分显示省略号 */
                    white-space: nowrap;/*规定段落中的文本不进行换行 */
                }
            }
        }
        .info-reference{
            width: 912px;
            .info-reference-title{
                display: flex;
                height: 34px;
                line-height: 32px;
                text-align: center;
                border-bottom: 1px solid #EDEDED;
                .color{
                    color: #666666;
                    font-weight: 400;
                }
                >div{
                    flex: 1;
                    text-align: center;
                    color: #333;
                    font-weight: bold;
                }
                
            }
        }
    }
}
</style>