<template>
	<div class="hnw-oss-img" @click="getImg">
	
            <el-image
                :style="{width:width+'px',height:width+'px'}"
                :src="picture"
				lazy
				:preview-src-list="srcList"
				
                @error="imageError()"
            ></el-image>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				picture:"",
				srcList:[]
			}
		},
		methods: {
			imageError(){

				console.log("图片加载失败")
				this.picture = "https://test.huanuowei.cn/images/static/index/zanwu.png"
			},
			async getImg(){
				if(this.preview){
					try {
						let imgs = await this.$api.getGoodsImgs(this.id)
						imgs.forEach(item => {
		
							if(!item.goods_image.includes('image.oos.huanuowei.cn')){
								item.goods_image = "https://image.oos.huanuowei.cn/upload/shop/store/goods/1780/" + item.goods_image
							}
						});
						this.srcList = imgs.map(item=>item.goods_image) || []
	
					} catch (error) {
						this.srcList = []
					}
				}
			}
		},
		created(){
			
			
			// getGoodsImgs
			if(!this.src){
				this.picture = "https://test.huanuowei.cn/images/static/index/zanwu.png"

			}else{
				if(!this.src.includes('image.oos.huanuowei.cn')){
					this.picture = "https://image.oos.huanuowei.cn/upload/shop/store/goods/1780/" + this.src
				}else{
					this.picture = this.src
				}
				if(this.preview){
					
					this.srcList = [this.picture]
				}
			}
			
			// if(!this.picture.includes('x-oss-process=image')){
			// 	this.picture = this.picture +"?x-oss-process=image/resize,p_25" 
			// }
			
			
		},
		props:{

			src:"",
			width:{
				default:"210"
			},
			id:"",
			preview:false
		}
	}
</script>
<style lang="scss">
	.hnw-oss-img{
		image{
			
		}
	}
</style>
