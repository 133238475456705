<template>
<div class="find-list">
  <div class="find-head">
      <div class="f-r hnw-area-center">
        <!-- <el-checkbox class="sele-btn" v-model="userNewList" @change="listChange">新列表</el-checkbox> -->
        <div class="sele-btn" :class="{'old-sele':!userNewList}" @click="listChange">
          {{ userNewList?"切换旧版":"切换新版" }}
        </div>
      </div>

  </div>

  <newList v-if="userNewList"></newList> 
  <oldList v-if="!userNewList"></oldList> 
</div>
</template>

<script>
import newList from "./newList.vue"
import oldList from "./oldList.vue"
export default {
    name:"findIndex",
    data(){
        return{
          userNewList:true
        }
    },
    methods:{
      listChange(){  
        this.userNewList=!this.userNewList
        localStorage.setItem("find-list-type", this.userNewList?"new":'old');
      }
    },
    created(){
      let type =  localStorage.getItem("find-list-type");
      this.userNewList = type=="new"
    },
    props:{
        
    },
    components:{
        newList,
        oldList
    },
}
</script>

<style lang="scss">
.find-list{
    .find-head{
      background: #fff;
      .f-r{
        display: flex;
        position: relative;
        .sele-btn{
          position: absolute;
          // right: 390px;
          left: 210px;
          top: 8px;
          cursor: pointer;
          z-index: 99;
          font-size: 14px;
          border: 1px solid  #C6C6C6;
          padding: 5px 8px;
          border-radius: 5px;
        }
        .old-sele{
          left: 130px;
          top: 10px;
        }
        .sele-btn:hover{
          color: red;
        }

      }
    }
}
  </style>