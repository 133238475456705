import Vue from 'vue'
import VueRouter from 'vue-router'
import api from "../common/api"
// 首页
import Index from "../pages/index/index.vue"
import Register from "../pages/login/Register.vue"
import ReSetPassword from "../pages/login/ReSetPassword.vue"

import Shopcar from "../pages/common/Shopcar.vue"
import Login from "../pages/login/Login"
// 首页页面
import NavHome from "../pages/index/NavHome.vue"
import IndexGoodsInfo from "../pages/index/info.vue"
// 品牌中心
import BrandCentre from "../pages/index/BrandCentre.vue"
// 折扣商品列表
import disGoods from "../pages/index/disGoods.vue"
//电子目录



import epcHome from "../pages/epcWeb/home.vue"
import epcVintree from "../pages/epcWeb/VInTree.vue"
import epcVinGoods from "../pages/epcWeb/VinGoods.vue"
import epcVinPurCart from "../pages/epcWeb/VinPurCart.vue"

// 旧版
import catalogHome from "../pages/catalog/home.vue"
import VinTree from "../pages/catalog/VInTree.vue"
import VinGoods from "../pages/catalog/VinGoods.vue"
import VinVeh from "../pages/catalog/Vehicle.vue"
import VinOeSearch from "../pages/catalog/OeSearch.vue"
import VinPartInfo from "../pages/catalog/PartInfo.vue"

import VinVehInfo from "../pages/catalog/VehInfo.vue"
import VinEpcImgList from "../pages/catalog/EpcImgList.vue"
// 集中采购
import PurIndex from "../pages/purchase/index.vue"
import PurList from "../pages/purchase/List.vue"
import PurInfo from "../pages/purchase/GoodsInfo.vue"

// 限时秒杀
import SeckilIndex from "../pages/seckil/index.vue"
// 找件
import FindIndex from "../pages/find/index.vue"
import FindList from "../pages/find/List.vue"
import FindDeta from "../pages/find/details.vue"
import FindInfo from "../pages/find/Info.vue"

import FindTest from "../pages/find/test.vue"
// 企业店铺
import StoreIndex from "../pages/store/index.vue"
import StoreHome from "../pages/store/home.vue"
import StoreBusinesses from "../pages/store/businesses.vue"
import StoreInfo from "../pages/store/Info.vue"
// 买一送一
import ClearInv from "../pages/clearInv/index.vue"
import brandTalk from "../pages/brandTalk/index.vue"
import ClearInvInfo from "../pages/clearInv/GoodsInfo.vue"
// 清库存
import Give from "../pages/give/index.vue"
import GiveInfo from "../pages/give/GoodsInfo.vue"
// 采购报表
import statement from "../pages/statement/index.vue"
// 积分商城
import inteIndex from "../pages/integral/index.vue"
import inteInfo from "../pages/integral/info.vue"
import inteSubOrder from "../pages/integral/subOrder.vue"
// 品牌中心
import BrandIndex from "../pages/brand/index.vue"
import BraInfo from "../pages/brand/Info.vue"
// 我的个人中心模块
import MyIndex from "../pages/my/index.vue"
// 服务中心
import Service from "../pages/index/service.vue"
import BodyImg from "../pages/index/bodyImg.vue"

import MyAddress from "../pages/my/Address.vue"
import MyCollect from "../pages/my/Collect.vue"
import MyCompany from "../pages/my/Company.vue"
import MyDemand from "../pages/my/Demand.vue"
import MyDiscounts from "../pages/my/Discounts.vue"
import MyMessage from "../pages/my/Message.vue"
// 账号信息
import MyAccount from "../pages/my/Account.vue"
import MyOrder from "../pages/my/Order.vue"

// 退换货详情页
import MyReSales from "../pages/my/ReSales.vue"
import MyBranchReSales from "../pages/my/BranchReSales.vue"
import MYRefund from "../pages/my/RefundInfo.vue"

// 订单详情
import MyOrderInfo from "../pages/my/orderInfo.vue"
import MyRePart from "../pages/my/RePart.vue"
import MyReBranchPart from "../pages/my/ReBranchPart.vue"

import MyInvoice from "../pages/my/Invoice.vue"
import SysStaff from "../pages/my/SysStaff.vue"
// 联盟调货
import unionIndex from "../pages/union/Index.vue"

import unHome from "../pages/union/Home.vue"
import unGoods from "../pages/union/GoodsList.vue"
import unClearGoods from "../pages/union/ClearGoods.vue"
import unHotGoods from "../pages/union/HotGoods.vue"
import unBrandGoods from "../pages/union/BrandGoods.vue"
import unGoodsInfo from "../pages/union/info.vue"
// 商品详情页


// 优百佳电子目录
import eleCatalogIndex from "../pages/elecatalog/index.vue"

Vue.use(VueRouter)

const routes = [

	{

		path: "/",
		component: Index,
		redirect: '/index/navhome/self',
		// redirect: '/brand',
		children: [
			// 子页面导航
			{
				path: "/index/navhome/:acc",
				component: NavHome
			},
			// 中心图
			{
				path: "/bodyImg",
				component: BodyImg
			},
			{
				path: "/index/goodsInfo",
				component: IndexGoodsInfo
			},

			{
				path: "/index/brand/:brand",
				component: BrandCentre
			},
			{
				path: "/index/disgoods/:type/:id/:commonid",
				component: disGoods
			},
			{
				path: "/purchase",
				component: PurIndex,

				children: [
					{
						path: "/purchase/index",
						component: PurList
					},
					{
						path: "/purchase/goodsinfo",
						component: PurInfo
					}
				]
			},

			{
				path: "/seckil",
				component: SeckilIndex
			},
			// 找件
			{
				path: "/find",
				component: FindIndex,
				children: [
					{
						path: "/find/index",
						component: FindList
					},

					{
						// path:"/find/details",
						path: "/find/details/:id",
						component: FindDeta
					},
					{

						path: "/find/info",
						component: FindInfo
					},
					{

						path: "/find/test",
						component: FindTest
					}
				]
			},
			// 清库存
			{
				path: "/clearInv",
				component: ClearInv,
			},
			// 清库存
			{
				path: "/talkBrand",
				component: brandTalk
			},
			{
				path: "/clearInv/goodsinfo",
				component: ClearInvInfo
			},
			// 买一送一
			{
				path: "/give",
				component: Give,
			},
			{
				path: "/give/goodsinfo",
				component: GiveInfo
			},
			// 采购报表

			{
				path: "/statement",
				component: statement,
			},
			// 积分商城
			{
				path: "/integral",
				component: inteIndex,
			},
			{
				path: "/integral/info",
				component: inteInfo,
			},
			{
				path: "/integral/suborder/:id/:num",
				component: inteSubOrder,
			},
			// 企业店铺
			{
				path: "/store",
				component: StoreIndex,
				redirect: '/store/index',
				children: [
					{
						path: "index",
						component: StoreHome
					},

					{
						path: "businesses",
						component: StoreBusinesses
					},
					{
						path: "businesses/:id",
						component: StoreBusinesses
					},
					{
						path: "/store/info",
						component: StoreInfo
					},
				]
			},

			{
				path: "/brand",
				component: BrandIndex
			},
			{
				path: "/brand/info",

				component: BraInfo
			},
			// 购物车
			{
				path: "/shopcar/:step/:type",
				component: Shopcar,
			},

			// 我的模块
			{
				path: "/my",
				component: MyIndex,
				children: [
					{
						path: "/my/address",
						component: MyAddress
					},
					{
						path: "/my/collect",
						component: MyCollect
					},
					{
						path: "/my/company",
						component: MyCompany
					},
					{
						path: "/my/demand",
						component: MyDemand
					},
					{
						path: "/my/discounts",
						component: MyDiscounts
					},
					{
						path: "/my/message",
						component: MyMessage
					},
					{
						path: "/my/account",
						component: MyAccount
					},
					{
						path: "/my/order",
						component: MyOrder,

					},

					{
						path: "/my/orderInfo/:id",
						component: MyOrderInfo
					},
					{
						path: "/my/RefundInfo/:id",
						component: MYRefund
					},
					{
						path: "/my/RePart",
						component: MyRePart
					},
					{
						path: "/my/ReSales/:id",
						component: MyReSales
					},
					{
						path: "/my/reBranchPart",
						component: MyReBranchPart
					},
					{
						path: "/my/BranchReSales/:id",
						component: MyBranchReSales
					},
					{
						path: "/my/invoice",
						component: MyInvoice
					},
					// 员工管理
					{
						path: "/my/sysstaff",
						component: SysStaff
					},
				]
			}
		]

	},
	{
		path: "/union/index",
		component: unionIndex,
		children: [
			{
				path: "/union/home",
				component: unHome
			},
			{
				path: "/union/allGoods",
				component: unGoods
			},
			{
				path: "/union/clear",
				component: unClearGoods
			},
			{
				path: "/union/hotGoods",
				component: unHotGoods
			},
			{
				path: "/union/brandGoods",
				component: unBrandGoods
			},
			{
				path: "/union/goodsinfo",
				component: unGoodsInfo
			}
		]
	},
	// 服务中心
	{
		path: "/service/:num",
		component: Service
	},
	{
		path: '/Register',
		name: 'Register',
		component: Register,

	},
	{
		path: "/ReSetPassword",
		component: ReSetPassword,
	},
	// 电子目录
	// 精准找件
	// infonum 判断进入详情页得模式
	{
		path: "/catalog/home/:queryType",
		component: catalogHome,
		children: [
			{
				path: "/catalog/tree/:vin/:groups",
				component: VinTree
			},
			{
				path: "/catalog/vinGoods/:vin",
				component: VinGoods
			},
			{
				path: "/catalog/vehicle/:brand/:num",
				component: VinVeh
			},
			{
				path: "/catalog/oesearch/:oecode/:num",
				component: VinOeSearch
			},

			{
				path: "/catalog/partinfo/:oecode/:brandCode/:queryType",
				component: VinPartInfo
			},
			{

				path: "/catalog/vehinfo/:oecode/:brandCode/:mcid",
				component: VinVehInfo
			},
			{
				path: "/catalog/epcimglist/:brandCode/:mcid/:vin/:num/:mid/:subgroup/:name",
				component: VinEpcImgList
			},

		]
	},

	{
		path: "/epc/home/:queryType",
		component: epcHome,
		children: [
			{
				path: "/epc/tree/:vin/:manufacturer_id",
				component: epcVintree
			},
			// 精准找件
			{
				path: "/epc/vinGoods/:vin",
				component: epcVinGoods
			},
			{
				path: "/epc/vinPurCart",
				component: epcVinPurCart
			},

		]
	},

	{
		path: '/Login',
		name: "Login",
		component: Login,
	},
	// 优百佳电子目录查询
	{
		path: '/elecatalog/index',
		component: eleCatalogIndex
	},
	// 江苏华诺威

]

const router = new VueRouter({
	//   mode: 'history',
	routes,
	scrollBehavior() {  //路由跳转后 在顶部显示
		return { x: 0, y: 0 };
	}
})

// 更新版本时切换页面刷新
router.beforeEach(async (to, from, next) => {
	// 版本升级
	let verRes = await api.handleVer({
		model: 2,
	});
	// console.log("verRes",verRes)
	let vers = localStorage.getItem("hnw-web-vers");
	if (vers != "undefined") {
		vers = JSON.parse(vers);

		if (vers != verRes) {
			localStorage.setItem("hnw-web-vers", JSON.stringify(verRes));
			location.reload(true);
			next()
		}
	}
	next()
})


export default router
