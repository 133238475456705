<template>
<div class="para-search">
    <div class="search">  
       
        <el-input v-model="searchValue" placeholder="请输入内容" class="input-with-select" size="mini" clear>
            <el-select v-model="searchKey" slot="prepend" placeholder="请选择">
                <el-option
                v-for="(param,index) in prammsList"
                :key="index"
                :label="param.title"
                :value="param.key">
                </el-option>
        </el-select>
        </el-input>

        <div v-if="showOption('timetype')">
            <div class="short">
                <span :class="{'active':searchParams.timetype=='today'}" @click="getList('today')">今天</span>
                <span :class="{'active':searchParams.timetype=='day'}" @click="getList('day')">最近三天</span>
                <span :class="{'active':searchParams.timetype=='week'}" @click="getList('week')">本周内</span>
                <span :class="{'active':searchParams.timetype=='lastweek'}" @click="getList('lastweek')">上周</span>
            </div>
        </div>
        <div class="heandle">
            <el-button size="mini" type="primary" @click="getList">搜索</el-button>
            <el-button size="mini" @click="reset">清空</el-button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            
            searchKey:'oecode',
            searchValue:'',
            // 所有的搜索参数
            searchParams:{
                // 时间
                date:[],
                // 时间范围，平台订单使用
                start_time:"",
                end_time:"",
                timetype:"",
                order_sn:"",
                oecode:"",
                goods_name:"",
                sccode:"",
                store_name:"",
                buyer_name:"",
                // 订单状态 发没发货 付没付款
                state_type:"",
                // 配件品牌
                oenaturebrand:"",
                // 仓库id
                war_id:"",
                order_from:"",
                storage:"",
                storage_num:"",
                oecarbrand:"",
                // 审核状态
                examine:"",
                goods_storage:"",
                // 订单类型
                order_type:"",
                mateMode:"",
                goods_state:""
            },

            showSearchBack:false,
            comTypes:[
            ],
            // 这次是搜索是否包含该
            keys:[],
            // 仓库列表
            storageList:[]
        }
    },
    methods:{
        async getList(timetype){
            
            
            if(this.$api[this.api]){
                let res = await this.$api[this.api]({
                    state:this.state,
                    ...this.searchData,
                    [this.searchKey]:this.searchValue,
                    order_state:this.order_state,
                    member_id:this.$store.state.member_id,
                    buyer_id:this.$store.state.buyer_id
                })
                this.$emit("searchEnd",res)

            }else{
                this.$emit("searchEnd","未找到对应api")
            }
        },
        dateChange(){
            this.searchParams.timetype = ""
            this.$emit("changeTimeType","")
        },
        reset(){
           this.searchValue=""
           this.getList()
            for(let key in this.searchParams){
                if(key!="curpage"&&key!="page"){
                    this.searchParams[key] = ""
                }
            }
            this.$emit("reset")
        },
        
        showOption(option){
            return this.keys.indexOf(option)!=-1
        }
    },
    async created(){
        
    },
    watch:{
        // 监听父组件的页码个个数变化
        "searchData.curpage":{
            deep:true,
            handler(n){
                this.getList()
            }
        },
        "searchData.page":{
            deep:true,
            handler(n){
                this.getList()
            }
        },
        "searchData.order_type":{
            deep:true,
            handler(n){
                this.getList()
            }
        },
        order_type(){
            this.getList()
        },
        order_state(){
            this.getList()
        },
        sort_type(){
            this.getList()
        },
        sort_way(){
            this.getList()
        }
    },
    props:{
        searchData:{
            type:Object,
            required:true
        },
        api:{
            type:String,
            required:true
        },
        order_state:"",
        // 商品按库存排序
        sort_type:"",
        sort_way:"",
        type:{
            default:""
        },
        prammsList:{
            require:true
        },
        state:"",
        onlyUi:{
            default:false
        },
    },
    mounted(){
        this.keys = Object.keys(this.searchData)
    }
}
</script>

<style lang="scss" scoped>
// 输入框高度
$inpH:20px;
.para-search{
    .search{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        .input-with-select{
            width: 300px;
            height: 28px;
            margin-top: 10px;
            margin-right: 10px;
            .el-select{
                width: 100px;
            }
        }
        .para-item{
            margin-top: 10px;
            margin-right: 10px;
            display: flex;
            .label{
                height: 28px;
                line-height: 28px;
                background: #ccc;
                text-align: center;
                font-size: 12px;
                width: 60px;
                box-sizing: border-box;
                padding-right: 5px;
            }
            .value{
                input{
                    outline: none;
                    border: 1px solid #E6E6E6;
                    height: 28px;
                    width: 120px;
                    box-sizing: border-box;
                    padding: 0 4px;
                    &:focus{
                        border: 1px solid #3E84D6;

                    }
                }
                .el-date-editor.el-input, .el-date-editor.el-input__inner{
                    width: 200px;
                }
                ::v-deep .el-date-editor{
                    .el-input__prefix{
                        display: none;
                    }
                    .el-input__icon{
                        line-height: 28px;
                    }    
                    .el-input__inner{
                        
                        height: 28px;
                        line-height: 28px;
                        padding: 0 4px;
                        border-radius: 0;
                    }
                }
            }
        }
        .heandle{
            
            .el-button{
                padding: 0 10px;
                text-align: center;
                line-height: 26px;
                height: 28px;
                margin-top: 10px;
            }  
        } 
    }
   
}
</style>