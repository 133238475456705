<template>
<div class="order-info">
    <template v-if="orderData">
        <div class="top-nav">
            <div class="re-bakc-box" style="display: flex;justify-content: center;align-items: center;cursor: pointer;line-height: 30px;" @click="$router.back()">
                <img src="../../assets/commom/reback.png" style="width: 22px;margin-right: 6px;" alt="" srcset="">
                <span style="position: relative;top: 3px;">返回上一页</span>
            </div>
           <!-- <div @click="toHome">
                我的华诺威
           </div>
            <i class="el-icon-arrow-right"></i>
            <div  @click="$router.push('/my/order')">
                我的订单
            </div>
            <i class="el-icon-arrow-right"></i>
            <div>
                订单号:
            </div>
            {{orderData.order_info.order_sn}} -->
        </div>
       
        <div class="order-msg">
            <div class="msg-left">
                <div>
                    订单号：{{orderData.order_info.order_sn}}
                </div>
                <div class="order-state">
                    {{ $hnwTools.getOrderStateText(orderData.order_info.order_state)}}
                </div>
                <div class="btn" v-if="orderData.order_info.order_state>3">
                    评价
                </div>
                
            </div>
            <div class="msg-right">
                <div class="order-step-title">
                    订单{{ $hnwTools.getOrderStateText(orderData.order_info.order_state)}}
                </div>
                <div class="order-step-list">
                    <template v-if="orderData.order_info.order_state!=-2">
                        <div class="order-step-item" v-for="(step,index) in stepList" :key="index" :class="{'active':stepIndex>index}">
                            <div class="step-left">
                                <div class="img-box">
                                    <img :src="stepIndex>index?step.img2:step.img" alt="" class="icon-img">
                                </div>
                                
                                <div class="tit">
                                    {{step.tit}}
                                </div>
                                <div class="time">
                                    {{ $hnwTools.formatDateTime(orderData.order_info[step.key]) ||''}}
                                </div>

                            </div>
                            <div class="step-right">
                                
                            <div class="img-box">
                                <img :src="stepIndex>index?require('../../assets/my/info/line2.png'):require('../../assets/my/info/line1.png')" alt="">
                            </div>
                            </div>
                        </div>

                        <div class="step-item-over"  :class="{'active':stepIndex>4}">
                            <div class="img-box">
                                    <div class="icon-img">
                                        <img src="../../assets/my/info/over.png" alt="" class="">
                                    </div>
                                </div>
                                <!-- #666 -->
                                <div class="tit">
                                    完成
                                </div>
                                <div class="time">
                                    {{ $hnwTools.formatDateTime(orderData.order_info.over_time) }}
                                </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="order-step-item active">
                            <div class="step-left">
                                <div class="img-box">
                                    <img src="../../assets/my/info/cancel.png" alt="" class="icon-img">
                                </div>
                                <!-- #666 -->
                                <div class="tit">
                                   订单已取消
                                </div>
                                <div class="time">
                                    {{orderData.order_info.cancel_remarks}}
                                    <!-- {{ $hnwTools.formatDateTime(orderData.order_info[step.key]) ||''}} -->
                                </div>

                            </div>
                        </div>
                    </template>
                </div>
                
            </div>
            
        </div>
        <!-- 物流信息 -->
        <div class="logis-msg">
            <div class="msg-left">
                
                <div>
                   <div class="label">
                        送货方式
                   </div>
                    <div class="value">
                        {{orderData.order_info.transport_type||"无"}}
                       
                    </div>
                </div>
                <div>
                   <div class="label">
                        物流单号
                   </div>
                    <div class="value">
                        {{orderData.order_info.shipping_code||"无"}}
                       
                    </div>
                </div>
                <div>
                   <div class="label">
                        备注
                   </div>
                    <div class="value">
                         {{orderData.order_info.buyer_remarks||"无"}}
                    </div>
                </div>
                
                <!-- <div>
                   <div class="label">
                         承运人： 
                   </div>
                    <div class="value">
                         无
                    </div >
                </div>
                <div>
                   <div class="label">
                        承运人电话： 
                   </div>
                    <div class="value">
                        无
                    </div >
                </div>
                <div>
                   <div class="label">
                         货运单号： 
                   </div>
                    <div class="value">
                        无
                    </div>
                </div> -->
            </div>
            <div class="msg-right">
               <div class="order-user-msg">
                    <div class="user-msg-item">
                        <div class="title">
                            卖家信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                卖家：
                                <span v-if="$store.state.for_branch">
                                    {{$store.state.shopInfo.shop_name}}
                                </span>
                                <span v-else-if="orderData.store_info.store_id=='1780'">
                                    {{orderData.store_info.company}}
                                </span>
                                <span v-else> 
                                    {{orderData.store_info.company_plat_name}}
                                </span>
                            </div>
                            <div class="msg-row" v-if="$store.state.for_branch||orderData.store_info.store_id=='1780'||orderData.store_info.store_id=='2197'"> 
                                <span v-if="$store.state.for_branch">
                                    {{$store.state.shopInfo.shop_area_info}}
                                </span>
                                <span v-else>
                                    {{orderData.store_info.area_info}}
                                    {{orderData.store_info.address}}
                                </span>
                            </div>
                            <div class="msg-row" v-else>
                                上海-上海-嘉定区 曹安公路4588号1号楼
                            </div>

                            <div class="msg-row" v-if="$store.state.for_branch||orderData.store_info.store_id=='1780'||orderData.store_info.store_id=='2197'">
                                电话：
                                <span v-if="$store.state.for_branch">
                                    {{$store.state.shopInfo.shop_contactphone}}
                                </span>
                                <span v-else>
                                    {{orderData.store_info.telphone}}
                                </span>
                            </div>
                            <div class="msg-row" v-else>
                                13399542525
                            </div>
                        </div>
                    </div>

                    <div class="user-msg-item">
                        <div class="title">
                            买家信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                买家：
                                <span>
                                
                                    {{orderData.buyer_info.true_name}}
                                </span>
                            </div>
                            <div class="msg-row">
                                地址：
                                <span>
                                    {{orderData.buyer_info.area_info}}
                                    {{orderData.buyer_info.address}}
                                </span>
                            </div>
                            <div class="msg-row">
                                手机号：
                                <span>
                                    {{orderData.buyer_info.mob_phone}}
                                </span>
                            </div>
                            <!-- <div class="msg-row">
                                买家备注：
                                <span>
                                    {{orderData.order_info.buyer_remarks}}
                                </span>
                            </div> -->
                        </div>
                    </div>
                    <div class="user-msg-item">
                        <div class="title">
                            配送信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                物流公司：
                                <span>
                                    {{orderData.order_info.e_name||"无"}}
                                </span>
                            </div>
                            <div class="msg-row">
                                物流单号：
                                <span>
                                    {{orderData.order_info.shipping_code||"无"}}
                                </span>
                            </div>
                            <div class="msg-row">
                                发货时间：
                                <span>
                                    {{ $hnwTools.formatDateTime(orderData.order_info.delay_time)||"无"}}
                                </span>
                            </div>
                            <div class="msg-row">
                                预计到货：
                                <span>
                                    {{$hnwTools.formatDateTime(orderData.order_info.takeover_time)||"无"}}
                                </span>
                            </div>
                        
                        </div>
                    </div>
                    <div class="user-msg-item">
                        <div class="title">
                            付款信息
                        </div>
                        <div class="msg-body">
                            <div class="msg-row">
                                付款方式：
                                <span>
                                    {{orderData.order_info.set_type}}
                                </span>
                            </div>
                        
                            <div class="msg-row">
                                付款时间：
                                <span>
                                    {{$hnwTools.formatDateTime(orderData.order_info.payment_time)|| ''}}
                                    <!-- 2020-11-01 08:30:37 -->
                                </span>
                            </div>
                            <div class="msg-row">
                                发票抬头：
                                <span>
                                    {{orderData.invoice.inv_title}}
                                </span>
                            </div>
                            <div class="msg-row">
                                发票税号：
                                <span>
                                    {{orderData.invoice.inv_code}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 买方卖方信息 -->
        
        <!-- 商品信息 -->
        <div class="order-goods-msg">
            <div class="store-list">
                <div class="store-msg">
                    华诺威总部
                    <img src="../../assets/commom/storeIcon.png" alt="">
                </div>
                <div class="goods-table">
                    <div class="head">
                        <div class="goods-info">商品详情</div>
                        <div>配件编码</div>
                        <div>单价</div>
                        <div>数量</div>
                        <div>实付款</div>
                        <!-- <div class="handle">操作</div> -->
                    </div>
                    <div class="body" >
                        <div class="row" v-for="goods in goodsList" :key="goods.goods_id">
                            <div class="goods-info">
                                 <div class="img-info" v-if="goods.type!=1">
                                    <div class="img-box">
                                        <HnwOssImg :width="80" :src="goods.goods_image"></HnwOssImg>
                                        <!-- <img class="goods-img" :src="goods.goods_image||require('../../assets/temp/mschuizi.png')" alt=""> -->
                                    </div>
                                    <div class="info">
                                        <div>
                                            <div class="name">{{goods.goods_name}}</div>
                                            <div class="bag" v-if="goods.type==2">集</div>
                                            <div class="bag" v-else-if="goods.type==3">秒</div>
                                        </div>
                                        <div>
                                            {{goods.oecode}}
                                        </div>
                                        <div class="vehicle">
                                            车型:{{goods.vehicletype}}
                                        </div>
                                    </div>
                                </div>

                                <div class="no-img-info" v-else>
                                    <div class="type">
                                        采购
                                    </div>
                                    <div class="info">
                                        
                                       <div>
                                            <div class="name">{{goods.goods_name}}</div>
                                            <div class="oecode">{{goods.oecode}}</div>
                                       </div>
                                       <div class="vehicle">
                                           
                                           车型:{{goods.vehicletype}}
                                       </div>
                                    </div>
                                </div>

                            </div>
                            <div>{{goods.sccode}}</div>
                            <div>{{goods.goods_price}}</div>
                            <div class="num">
                                {{goods.goods_num}}
                                <span class="goods-zbyh-tit" v-if="orderData.order_info.order_state!=4&&orderData.order_info.chain==3&&Number(goods.storage)==0">
                                    总仓发货，预计1~3天发货
                                </span>
                            </div>
                            <div v-if="goods.goods_commend=='201'">
                                详询销售
                            </div>
                            <div v-else>¥ {{Number(goods.goods_price) *100 * Number(goods.goods_num) /100 }}</div>

                            <!-- <div class="handle">
                                <div>立即评价</div>
                                <div class="btn">
                                    <img src="../../assets/my/lig.png" alt="">
                                    立即购买
                                </div>
                            </div> -->
                        </div>

                    </div>
                </div>
            </div>
            
        </div>

        <div class="order-price-msg">
            <!-- 支付凭证 -->
            <div class="pay-voucher" v-if="orderData.order_info.chain==3&&orderData.order_info.set_type=='对公转账'&&orderData.order_info.qrcode">
                <div class="label">
                    支付凭证:
                </div>
                <div class="img-box">
                    
                    <img :src="orderData.order_info.qrcode" @click="redBigImg(orderData.order_info.qrcode)"  alt="">
                </div>
            </div>

            <div class="pay-voucher" v-if="orderData.order_info.chain==3&&orderData.order_info.logistics_list">
                <div class="label">
                    发货凭证:
                </div>
                <div class="img-box">
                    <img :src="img.url" v-for="(img,index) in orderData.order_info.logistics_list" :key="index" @click="redBigImg(img.url)" alt="">
                    <!-- <img :src="orderData.order_info.qrcode" alt=""> -->
                </div>
            </div>

            <div class="price-item">
                <div class="label">
                    商品总额:
                </div>
                <div class="value">
                   ¥{{orderData.order_info.total_sum}}
                </div>
            </div>
            <div class="price-item">
                <div class="label">
                    运费:
                </div>
                <div class="value">
                  ¥0
                </div>
            </div>

            <div class="price-item">
                <div class="label">
                    实付款:
                </div>
                <div class="value">
                    ¥{{orderData.order_info.total_sum}}
                </div>
            </div>

        </div>
    </template>
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</div>
</template>

<script>
import HnwOssImg from "../../components/hnwOssImg.vue";
export default {
    data(){
        return{
            orderData:{
                order_info:{},
                store_info:{},
                buyer_info:{},
                invoice:{}
            },
            stepList:[
                {
                    img2:require("../../assets/my/info/start.png"),
                    tit:"提交订单",
                    key:'add_time'
                },
                {
                    img:require("../../assets/my/info/pay1.png"),
                    img2:require("../../assets/my/info/pay2.png"),
                    key:'payment_time',
                    tit:"付款成功"
                },
                {
                    img:require("../../assets/my/info/sto1.png"),
                    img2:require("../../assets/my/info/sto2.png"),
                    key:'delay_time',
                    tit:"商品出库"
                },
                {
                    img:require("../../assets/my/info/car1.png"),
                    img2:require("../../assets/my/info/car2.png"),
                    key:'takeover_time',
                    tit:"等待收货"
                }
            ],
            // 物流列表
            logisList:[
                {
                    day:"2020-08-08",
                    week:"周六",
                    time:"09:08:22",
                    desc:"您已在北京华诺威完成取件，感谢适用菜鸟驿站，期待再次为你服务"
                },
                {
                    day:"",
                    week:"",
                    time:"",
                    desc:""
                },
                {
                    day:"",
                    week:"",
                    time:"",
                    desc:""
                },
                {
                    day:"",
                    week:"",
                    time:"",
                    desc:""
                },
            ],
            // 步骤进度
            stepIndex:1,
            goodsList:[],
            dialogVisible:false,
            dialogImageUrl:""
        }
    },
    methods:{
        toHome(){
				
            if(this.$store.state.shop&&this.$store.state.shop!=1780){
                console.log(`${this.$store.state.shop}子店铺`)
                this.$router.push(`/store/businesses/${this.$store.state.shop}`)
            }else if(this.$store.state.for_branch){
                this.$router.push(`/store/businesses/${this.$store.state.for_branch}`)
            }else{

                console.log("没有shop")
                if(this.pageMode==2){
                    this.$router.push('/find/index')
                }else{
                    this.$router.push('/')
                }
            }
        },
         redBigImg(url){
            this.dialogImageUrl = url
            this.dialogVisible  = true
        },
    },
    async created(){
        this.orderData =  await this.$api.getOrderInfo({
            id:this.$route.params.id
        })
        
        this.goodsList = this.orderData.goods
        this.goodsList.forEach((goods)=>{
            // 处理不显示
            if(goods.goods_commend==201){
                
                let total =  this.$hnwTools.times(goods.goods_price, goods.goods_num)
                this.orderData.order_info.total_sum = this.$hnwTools.minus(this.orderData.order_info.total_sum,total)
                goods.goods_price = "详询销售"
            }
        })
        
        this.logisList[0] = {

            day:this.$hnwTools.formatDateTime(this.orderData.order_info.add_time,"ymd"),
            week:"",
            time:this.$hnwTools.formatDateTime(this.orderData.order_info.add_time,"hms"),
            desc:"订单已提交"
        }
        
        this.stepIndex = Number(this.orderData.order_info.order_state) +1
        if(this.stepIndex==1){
            if(this.orderData.order_info.set_type=='支付宝'||this.orderData.order_info.set_type=='微信'){
                if(this.orderData.order_info.cus_id=='1'){
                    this.stepIndex=2
                }
            }
        }
        if(this.stepIndex>3){
            this.stepList[3].tit = "确认收货"
        }
        
        if(this.orderData.order_info.chain==3){

            this.goodsList.forEach(async (goods)=>{
                this.$hnwTools.formatGoodsKey(goods)
                this.$handleStorePrice(goods)
            })
            if(this.orderData.order_info.logistics_list){
                this.orderData.order_info.logistics_list = JSON.parse(this.orderData.order_info.logistics_list)
            }
        }

        
    },
    components:{
        HnwOssImg
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.order-info{
    
    width: 100%;
    box-sizing: border-box;
    >div{
        background: #fff;
        margin-top: 20px;
    }
    .el-dialog__wrapper{
        background: transparent;
    }
    .top-nav{
        display: flex;
        align-items: center;
        background: $backGray;
        color: #666;
        margin-top: 10px;
        >div{
            cursor: pointer;
        }
        i{

        }
    }
    .order-msg{
        display: flex;
        height: 190px;
        box-sizing: border-box;
        margin-top: 10px;
        >div{
            padding: 20px;
            box-sizing: border-box;
        }
        .msg-left{
            text-align: center;
            min-width: 255px;
            border-right: 1px solid #ccc;
            
            .order-state{
                color: #3E84D6;
                font-size: 24px;
                margin: 35px 0;
            }
            .btn{
                width: 88px;
                height: 24px;
                line-height: 24px;
                cursor: pointer;
                border: 1px solid #CCCCCC;
                margin: 0 auto;
            }
        }
        .msg-right{
            width: 100%;
            .order-step-title{
                padding-bottom: 20px;
            }
            .order-step-list{
                display: flex;
                text-align: center;
                
                .order-step-item{
                    flex: 1;
                    display: flex;
                    color: #666;
                    >div{
                        .img-box{
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .step-left{
                        img{
                            
                        }
                        
                        .time{
                            margin-top: 10px;
                        }
                    }
                    .step-right{
                        color: #3E84D6;
                        .line{
                            height: 1px;
                            width: 110px;
                            border-top: 1px dashed #3E84D6;
                        }
                    }
                }
                .step-item-over{
                    width: 100px;
                    color: #666;
                    .img-box{
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .icon-img{
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background: #666;
                            img{

                            }
                        }
                    }
                    .time{
                        margin-top: 10px;
                    }
                }
                .active{
                    color: #3E84D6;
                   
                }
                .active.step-item-over{
                     .icon-img{
                       background: #3E84D6;
                    }
                }
            }
        }
    }
    .logis-msg{
        display: flex;
        height: 250px;
        >div{
            padding: 20px;
            box-sizing: border-box;
        }
        .msg-left{
            min-width: 255px;
            max-width: 255px;
            border-right: 1px solid #ccc;
            position: relative;
            overflow: hidden;
            >div{
                // display: flex;
                margin-bottom: 15px;
                text-align: center;
               .label{
                   font-size: 16px;
               }
               .value{
                    padding-top: 10px;
                    color: red;
                    font-size: 24px;
                    max-height: 82px;
                    overflow: hidden;
               }
            }
            &::before{
                content: '';
                position: absolute;
                height: 20px;
                width: 1px;
                background: #ccc;
                right: -1px;
                top: -20px;
            }
        }
        .msg-right{
            flex: 1;
            padding: 0;
            overflow: auto;
            .log-list{
                padding: 20px;
                padding-left: 120px;
                box-sizing: border-box;
                .log-item{
                    display: flex;
                    margin-bottom: 20px;
                    >div{
                        margin-right: 20px;
                    }
                    .date{
                        width: 75px;
                    }
                    .dot{
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        background: $gray;
                        position: relative;
                        .choose{
                            background: #3E84D6;
                            border-radius: 50%;
                            i{
                                color: #fff;
                                font-size: 12px;
                                position: relative;
                                left: 1px;

                            }
                        }
                        .shu{
                            background: $gray;
                            width: 1px;
                            position: absolute;
                            bottom: -20px;
                            left: calc(50% - 1px);
                            height: 20px;
                        }
                    }
                    .week{
                        width: 28px;
                    }
                    .time{
                        width: 56px;
                    }
                    .msg-text{

                    }
                }
            }
        }
    }
    
    .order-user-msg{
        height: 150px;
        display: flex;
        .user-msg-item{
            flex: 1;
            padding: 20px;
            padding-bottom: 0;
            border-right: 1px solid #ccc;
            .title{
                padding-bottom: 20px;
                color: #3E84D6;
            }
            .msg-body{
                .msg-row{
                    margin-bottom: 7px;
                    >span{

                    }
                }
            }
        }
        .user-msg-item:last-child{
            border: none;
        }
    }
    .order-goods-msg{
        padding: 10px;
        .store-list{
            .store-msg{
                padding: 20px 0;
                img{
                    margin-left: 5px;
                }
            }
            .goods-table{
                .head{
                    display: flex;
                    height: 38px;
                    line-height: 38px;
                    background: $backGray;
                    text-align: center;
                    >div{
                        flex: 1;
                    }
                    .goods-info{
                        min-width: 300px;
                        max-width: 300px;
                    }
                    .handle{

                    }
                }
                .body{
                    margin-top: 20px;
                    .row{
                        display: flex;
                        padding: 20px 0;
                        border:1px solid #ccc;
                        >div{
                            flex: 1;
                            padding-top: 10px;
                            text-align: center;
                        }
                       .goods-info{
                           min-width: 300px;
                           max-width: 300px;
                            padding-top: 0;
                           padding-left: 10px;
                           .img-info{
                                display: flex;
                                .img-box{
                                    width: 80px;
                                    height: 80px;
                                    display: flex;
                                    border: 1px solid #ccc;
                                    justify-content: center;
                                    align-items: center;
                                    .goods-img{
                                        width: 70px;
                                        height: 52px;
                                    }
                                }
                                .info{
                                    margin-left: 10px;
                                    text-align: left;
                                    flex: 1;
                                    max-width: 200px;
                                    >div{
                                        display: flex;
                                        
                                        margin-top: 10px;
                                        .name{
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                        .bag{
                                            width: 32px;
                                            height: 20px;
                                            line-height: 20px;
                                            text-align: center;
                                            background: #3E84D6;
                                            color: #fff;
                                            margin-left: 10px;
                                        }
                                    }
                                    .vehicle{
                                        color: #666666;
                                    }
                                }
                            }

                            // 无图模式
                            .no-img-info{
                                display: flex;
                                .type{
                                    width: 32px;
                                    height: 20px;
                                    line-height: 20px;
                                    margin-right: 5px;
                                    text-align: center;
                                    background: #3E84D6;
                                    color: #fff;
                                }
                                .info{
                                    flex: 1;
                                    min-width: 125px;
                                    
                                    >div{
                                        display: flex;
                                        .name{
                                            font-weight: 700;
                                            margin-right: 10px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            max-width: 140px;
                                        }
                                        .oecode{
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            max-width: 120px;
                                        }
                                    }
                                    .vehicle{
                                        color: #666;
                                        margin-top: 10px;
                                    }
                                }
                            }
                       }
                       .num{
                            position: relative;
                            .goods-zbyh-tit{
                                    position: absolute;
                                    left: 50%;
                                    // top: -20px;
                                    bottom: 20px;
                                    width: 200px;
                                    transform: translateX(-50%);
                                    text-align: center;
                                    
                                    padding-left: 10px;
                                    // color: #999;
                                    color: red;
                            }
                       }
                       .handle{
                           display: flex;
                           flex-direction: column;
                           align-items: center;
                           >div{
                               cursor: pointer;
                           }
                           .btn{
                                margin-top: 10px;
                                width: 75px;
                                height: 28px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: #FFFFFF;
                                border: 1px solid #CCCCCC;
                                border-radius: 2px;
                                >img{
                                    margin-right: 2px;
                                }
                           }
                       } 
                    }
                }
            }
        }

    }
    .order-price-msg{
        margin-top: 0;
        padding: 20px 60px 40px 0;
        position: relative;
        .price-item{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;
            .label{
                width: 70px;
                text-align: right;
            }
            .value{
                width: 80px;
                padding-left: 10px;
            }
        }
        .price-item:last-child{
            color: #FF2C2C;
            .value{
                font-size: 18px;
            }
        }
        .pay-voucher{
            position: absolute;
            left: 10px;
            top: 20px;
            display: flex;
            align-items: center;
            .img-box{
                img{
                    cursor: pointer;
                    width: 100px;
                    margin-left: 5px;
                }
            }
        }
    }
}
</style>