<template>
  <div class="store-index">
    
    <router-view/>
   
  </div>
</template>

<script>
export default {
  created(){
    
    if(this.$store.state.member_id){
      this.$store.dispatch("getAdvCart")
    }
  }
}
</script>

<style>

</style>