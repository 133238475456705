<template>
    <div class="dis-goods-list">
        <div class="goods-list  hnw-area-center">
            <el-row  :gutter="8">
                
                <el-col :xs="12" v-for="(item,index) in seckiList" :key="index"  :sm="12" :lg="{span: '4-8'}" class="goods-col">
                    
                    <div class="goods-card" @click="goRecommend(item)">
                        <div class="seckill-img">
                            <div class="img-box" >
                                
                                <HnwOssImg :src="item.groupbuy_image"></HnwOssImg>
                            </div>
                            <!-- <img :src="item.groupbuy_image" alt=""> -->
                            <div class="count-down-box" v-if="item.state==20">
                                <div class="time-of-day" v-if="item.leftDay">
                                    <div class="time-size">
                                    {{item.leftDay}}
                                    </div>
                                    <div class="time-color">天</div> 
                                </div>
    
                                <CountDwon bgrColor="#fff" :splitorText="splist" :bor="false" marginTop="6px" splitorColor="#fff" fontColor="#FF2020" fontSize="14px" :timer="item.leftTime" minWidth="19px" width="19px"></CountDwon>
                            </div>
                            <div class="count-down1" v-if="item.state==40">
                                    {{$hnwTools.formatDateTime(item.start_time,'dhms')}}
                                    开始
                            </div>
                            <div class="count-down2" v-if="item.state==32">
                                <!-- <img src="../../assets/index/index/countdown1.png" alt=""> -->
                                <div class="timekeeping-text">已结束</div>
                            </div>
                        </div>
                        <div class="seckill-info">
                            <div class="seckill-name">
                                {{item.groupbuy_name}}
                            </div>
                            <div class="seckill-quantity">
                                <!-- 限量{{item.buy_total}}件 -->
                                限量{{item.goods_storage}}件
                            </div>
                            <div class="seckill-schedule">
                                <div class="progress-body">
                                    <div class="progress1" :style="{width:item.buy_quantity/item.buy_total*100+'%'}"></div>
                                </div>
                                    <div class="sold">已售 {{item.buy_quantity}} 件</div>
                            </div>
                            <div class="seckill-price">
                                <div class="blue" v-if="!$store.state.member_id" >
                                    会员可见
                                </div>
                                <div class="price" v-else-if="item.state==40" >
                                    
                                    <div style="font-size: 14px; color: #FF2020; margin-top: 2px;">价格待定</div>
                                </div>
                                
                                <div v-else class="price">
                                    <div style="font-size: 14px; color: #FF2020; margin-top: 2px;">￥</div>
                                    <div class="current"> {{item.groupbuy_price}}</div>
                                    <div class="original">￥{{item.goods_price}}</div>
                                </div>
                                <div class="seckill-btn" v-if="item.state==20">
                                    
                                    {{ item.goods_storage>0?"马上抢":"已抢光" }}
                                </div>
                                <div class="seckill-btn" v-if="item.state==40">未开始</div>
                            </div>
                        </div>
                    </div>
                 
                </el-col>
            </el-row>
            
        </div>
    </div>
    </template>
    
    <script>
    import HnwOssImg from "../../components/hnwOssImg.vue";
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
    export default {
        data(){
            return{
                storeGoodsList:[],
                seckiList:[],
                splist:['时','分','秒'],
                swiperOption: { 
					slidesPerView: 5, // 一次显示 5 个 slides
                    spaceBetween: 10, // slides 之间的间距（可选）
                    loop: true, // 循环模式
                    autoplay: {
                        delay: 3000, // 每 3 秒切换一次
                        disableOnInteraction: false, // 用户交互后是否继续自动播放
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
				},
            }
        },
        methods:{
            goRecommend(acti){
				if(acti.state==20){
                    if(acti.goods_storage>0){
                        acti.preRou = "首页"
                        let rou = '/index/goodsinfo'
                        localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                        
                        this.$router.push(rou)
                    }else{
                        this.$warMsg("商品已抢光")
                    }
                }else{
                    this.$warMsg("秒杀未开始")
                }
				
			},
            handlerDay(){

                // 计算即将开始时间
                // (acti.totalsum_now*10000) / (acti.totalsum*10000*10000)
                // "overList"


                let arrs = ["seckiList"]

                let key = ""
                let dayTime =  60*60*24*1000
                arrs.forEach(arr=>{

                    if(arr=='prefoList'){
                        key = "start_time"
                        
                    }else{
                        key = "end_time"
                    }
                    try{

                        this[arr].forEach(acti=>{
                            let leftTime = 0
                            if(arr=='prefoList'){
                                
                                acti[key] = acti[key]*1000
                                
                                leftTime =  acti[key] - new Date()
                                acti.isNow = false
                            }else{
                                acti.isNow = true

                                if(arr=='seckiList'){
                                    // 秒杀未开始/开始在同一数组 用状态判断
                                    if(acti.state==40){
                                        key = "start_time"
                                    }
                                    
                                }
                                if(key=='start_time'){
                                    leftTime =  acti[key]*1000 - new Date()
                                    
                                }else{
                                    leftTime =  acti[key] - new Date()

                                }
                            }
                                                
                            if(leftTime>dayTime){
                                let leftDay = parseInt(leftTime/dayTime)
                                this.$set(acti,"leftDay",leftDay)
                                // 剩余总时间减去大于一天的天数
                                
                                this.$set(acti,"leftTime", acti[key] - leftDay*dayTime )
                                
                            }else{
                                this.$set(acti,"leftTime",acti[key])
                            }

                        
                            if(arr=='nowList'||arr=='overList'){
                                // let rote = parseInt(acti.totalsum_now*100) / (acti.totalsum*100) *100
                                
                                let rote = ""
                                if(Number(acti.totalsum)>0){
                                    rote = this.$hnwTools.div(acti.totalsum_now,acti.totalsum) *100
                                }else{
                                    rote =  0 
                                }
                                this.$set(acti,"rote",Number(rote.toFixed(2)))
                            }   
                            // 标记
                            if(arr=="overList"){
                                this.$set(acti,"notbuy",'over')
                            }else if(arr=='prefoList'){

                                this.$set(acti,"notbuy",'pre')
                            }  

                        })
                    }catch(e){
                        // console.log(e);
                        // console.log(arr);
                    }
                    
                    
                })
            },
        },
        async created(){
            // 推荐
            let chain = ""
            if(this.$store.state.for_branch){
                chain = 3
            }else{
                chain = 1
            }
            let seckiList = await this.$api.getSeckiList({ 
                mark:1,
                chain,
                shop:this.$store.state.for_branch||this.$store.state.shop
            })
            let arr = await this.$api.getSeckiList({
                mark:2,
                chain,
                shop:this.$store.state.for_branch||this.$store.state.shop,
            })
            seckiList.forEach(item=>{
                // 丹阳仓
                if(this.$store.state.is_sh==0){
                    item.goods_storage = item.goods_stcids
                }
            })
            let goodsWithPositiveStorage = seckiList.filter(item => item.goods_storage > 0);
            let goodsWithZeroOrNegativeStorage = seckiList.filter(item => item.goods_storage <= 0);
            
            // 将两部分数组连接起来，形成新的数组
            seckiList = goodsWithPositiveStorage.concat(goodsWithZeroOrNegativeStorage);
            
            this.seckiList = [
                ...seckiList,
                ...arr,
                
            ]
            console.log("this.seckiList",this.seckiList);
            this.handlerDay()
            
        },
        components:{
			swiper,
			swiperSlide,
			HnwOssImg
		},
        props:{
            
        }
    }
    </script>
    
    <style lang="scss">
    .dis-goods-list{
        .goods-list{
            background: $backGray;
            .el-col-lg-4-8 {
                width: 20%;
            }
            .goods-card{
                background: #fff;
                cursor: pointer;
                height: 336px;
                display: flex;
                margin-bottom: 10px;
                flex-direction: column;
                // justify-content: space-between;		
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                .seckill-img{
                    position: relative;
                    // width: 290px;
                    // height: 210px;
                    display: flex;
                    justify-content:center;
                    .img-box{
                        // cursor: pointer;
                        height: 210px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            
                            width: 210px;
                            height: 210px;
                        }
                    }
                    
                    .count-down-box{
                       
                        // width: 200px;
                        padding: 0 5px 0 44px;
                        height: 32px;
                        display: flex;
                        position: absolute;
                        top: -1px;
                        left: 0;
                        background-color: #f00;
                        border-radius: 0 0 12px 0 ;
                        .time-of-day{
                            // width: 44px;
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            color: #000;
                            z-index: 99;
                            display: flex;
                            .time-size{
                                min-width: 20px;
                                height: 20px;
                                text-align: center;
                                line-height: 20px;
                                color: #f00;
                                background-color: #fff;
                            }
                            .time-color{
                                color: #fff;
                                margin: 5px 3px 0;
                                font-size: 12px;
                                
                            }
                        }
                        .timekeeping{
                            position: absolute;
                            top: 11px;
                            left: 11px;
                            color: #333333;
                        }
                    }
                    .count-down1{
                        // width: 92px;
                        height: 32px;
                        line-height: 32px;
                        display: flex;
                        position: absolute;
                        top: -1px;
                        left: 0;
                        padding: 0 10px;
                        background-color: #f00;
                        border-radius: 0 0 12px 0 ;
                        color: #fff;
                    }
                    .count-down2{
                        width: 92px;
                        height: 32px;
                        line-height: 32px;
                        display: flex;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #999999;
                        border-radius: 0 0 12px 0 ;
                        // .timekeeping{
                        // 	position: absolute;
                        // 	top: 11px;
                        // 	left: 11px;
                        // 	color: #333333;
                        // }
                        .timekeeping-text{
                            // position: absolute;
                            // top: 11px;
                            // left: 20px;
                            color: #fff;
                            margin-left: 20px;
                        }
                    }
                }
                .seckill-info{
                    padding: 0 10px;
                    position: relative;
                    .seckill-name{
                        margin-top: 10px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #333333;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .seckill-quantity{
                        margin-top: 10px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #999999;
                    }
                    .seckill-schedule{
                        margin-top: 10px;
                        display: flex;
                        margin-bottom: 10px;
                        .progress-body{
                            display: flex;
                            // width: 180px;
                            flex: 1;
                            height: 8px;
                            margin-top: 3px;
                            background-color: #FEE8EA;
                            border-radius:  4px;
                            overflow: hidden;
                            .progress1{
                                // width: 200px;
                                // height: 14px;
                                text-align: center;
                                line-height: 14px;
                                background-color: #DD5044;
                                color: #fff;
                                border-radius:  4px 0 0 4px ;
                            }
                            
            
                        }		
                            .sold{
                                flex: 1;
                                text-align: center;
                                font-size: 14px;
                                color: #999999;
                                max-width: 100px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                // margin-left: 10px;
                            }
                    }
                    .seckill-price{
                        // position: absolute;
                        // bottom: -40px;
                        // width: 270px;
                        display: flex;
                        justify-content:space-between;
                        height: 28px;
                        line-height: 28px;
                        .price{
                            display: flex;
                            .current{
                                font-size: 24px;
                                color: #FF2020;
                                
                            }
                            .original{
                                font-size: 14px;
                                text-decoration: line-through;
                                color: #999999;
                                margin-top: 2px;
                                margin-left: 5px;
                            }
                        }
                        .seckill-btn{
                            width: 68px;
                            height: 28px;
                            background: #FFD101;
                            border-radius: 6px;
                            color: #333333;
                            font-size: 14px;
                            text-align: center;
                            cursor:pointer; 
                        }
                    }
                }
            }
        }
    }
    </style>